import React from 'react'

const KwikTripLiveDropNotice = ({ shipment }) => {

    if (!shipment) return null

    if (shipment.customerId !== "kwiktrip") return null

    const hasRequiredCustomField = shipment.customFields?.some(
        field => field.fieldId === 10324 && field.value === "drop"
    )

    if (!hasRequiredCustomField) return null

    return (
        <div className='row mt-3'>
            <div className='col-12 text-center'>
                <div className="alert alert-warning border-warning rounded-pill d-inline-block text-center font-weight-bold" role="alert">
                    All drivers must slide tandems back before dropping trailers.
                </div>
            </div>
        </div>
    )
}

export default KwikTripLiveDropNotice