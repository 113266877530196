import React from 'react'
import DocumentsModalSingleShip from './DocumentsModalSingleShip'
import UploadDocsPerStop from './UploadDocsPerStop/UploadDocsPerStop'

const DocumentsModal = ({ show, isUploadDocsPerStop, onHide, onHideDocs, id, documents, updateWasSubmitted, shipment }) => {

  if (!shipment) return null


  let showDocumentsPerStop = false
  if (shipment?.stops && shipment.stops.length && isUploadDocsPerStop) {
    showDocumentsPerStop = true
  }

  // if shipment.stops exists and has length
  if (showDocumentsPerStop) {
    return (
      <>
        <UploadDocsPerStop
          show={show}
          onHide={onHide}
          shipment={shipment}
        />
      </>
    )
  } else {

    return (
      <>
        <DocumentsModalSingleShip
          show={show}
          onHide={onHide}
          onHideDocs={onHideDocs}
          id={id}
          documents={documents}
          updateWasSubmitted={updateWasSubmitted}
        />
      </>
    )
  }
}

export default DocumentsModal