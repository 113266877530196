import React, { Component } from "react";

import Authentication from "../APIs/Authentication";
import UploadFile from "../APIs/UploadFile";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import * as moment from "moment";
import axios from "axios";
import AcceptShipment from "../APIs/AcceptShipment";
import DeclineShipment from "../APIs/DeclineShipment";
import MessagesRead from "../APIs/MessagesRead";
import SendMessage from "../APIs/SendMessage";
import SubmitUpdate from "../Components/ShipmentCard/Components/SubmitUpdate";
import MailIcon from "../SmallComponents/MailIcon";
import AcceptDeclineStatus from "./AcceptDeclineStatus/AcceptDeclineStatus";
import UserContext from "../UserDataContext";
import DateChecker from "../CustLogicAndFilters/DateChecker";
import SendEmail from "../Helpers/SendEmail";
import SendDeclineShipmentEmail from "../Helpers/SendDeclineShipmentEmail"
import CheckDockSchedulerQualification from "../APIs/CheckDockSchedulerQualifcation.js";
import { getShipmentV4 } from "../APIs/Helpers";
import KwikTripLiveDropNotice from "./KwikTripLiveDropNotice/KwikTripLiveDropNotice.js";

import * as SCC from "../Components/ShipmentCard/Components/index";

class ShipmentCard extends Component {

  static contextType = UserContext;
  constructor() {
    super();
    this.documentUploadHandleChangeDocSelect = this.documentUploadHandleChangeDocSelect.bind(
      this
    );
    // this.handleSubmitUpload = this.handleSubmitUpload.bind(this);
    // this.handleSubmitUpdateShipment = this.handleSubmitUpdateShipment.bind(
    //   this
    // );
    this.handleChangeGeneric = this.handleChangeGeneric.bind(this);
    // this.handleChangeFileChange = this.handleChangeFileChange.bind(this);
    this.fileInput = React.createRef();
    this.shipmentID = React.createRef();
    this.shipmentIDUpdate = React.createRef();
    this.onHideDocs = this.onHideDocs.bind(this);
    this.modalOpenDocs = this.modalOpenDocs.bind(this);
    this.modalOpenMessaging = this.modalOpenMessaging.bind(this);
    this.onHideMessages = this.onHideMessages.bind(this);
    this.setShipmentStatus = this.setShipmentStatus.bind(this);
    this.markMessageReadOnOpen = this.markMessageReadOnOpen.bind(this);
    this.handleAcceptShipment = this.handleAcceptShipment.bind(this);
    this.handleDeclineShipment = this.handleDeclineShipment.bind(this);
    this.handleAcceptShipmentChanges = this.handleAcceptShipmentChanges.bind(
      this
    );
    this.handleDeclineShipmentChanges = this.handleDeclineShipmentChanges.bind(
      this
    );
    this.populateChargeNotes = this.populateChargeNotes.bind(this);
    this.startUpAndUpdateLogic = this.startUpAndUpdateLogic.bind(this);
    this.checkBilledVsEstCost = this.checkBilledVsEstCost.bind(this);
    this.genericModalShow = this.genericModalShow.bind(this);
    this.genericModalHide = this.genericModalHide.bind(this);
    this.hideSchuffGPSModal = this.hideSchuffGPSModal.bind(this);
    this.tableEditModeOnOffCard = this.tableEditModeOnOffCard.bind(this);
    this.tableEditCheckEvent = this.tableEditCheckEvent.bind(this);
    this.hashForTableEditCheckboxID = this.hashForTableEditCheckboxID.bind(
      this
    );
    this.hilightAndHideGenerator = this.hilightAndHideGenerator.bind(this);
    this.condRenderCardBodyDetail = this.condRenderCardBodyDetail.bind(this);
    this.showDetailsForms = this.showDetailsForms.bind(this);
    this.showUpdateAlert = this.showUpdateAlert.bind(this);
    this.localUpDateSingleShipment = this.localUpDateSingleShipment.bind(this);
    this.checkForUnreadMessages = this.checkForUnreadMessages.bind(this);
    this.markMessagesReadLocally = this.markMessagesReadLocally.bind(this);
    this.copyInvoice2ToInvoicePro = this.copyInvoice2ToInvoicePro.bind(this);
    this.checkProPlusInvoice = this.checkProPlusInvoice.bind(this);
    this.checkDateSetState = this.checkDateSetState.bind(this);
    this.shipmentObjUpdateEvent = this.shipmentObjUpdateEvent.bind(this);
    this.shipmentObjUpdateDirect = this.shipmentObjUpdateDirect.bind(this);
    this.showHilightOrHide = this.showHilightOrHide.bind(this);
    this.setAndShowGenericModal = this.setAndShowGenericModal.bind(this);

    this.buildFormDataForShipmentUpdate = this.buildFormDataForShipmentUpdate.bind(
      this
    );
    this.documentUploadFileChangeHandler = this.documentUploadFileChangeHandler.bind(
      this
    );
    this.documentUploadBuildFormData = this.documentUploadBuildFormData.bind(
      this
    );
    this.addAccessorialRequest = this.addAccessorialRequest.bind(this);
    this.clearAddedAccessorials = this.clearAddedAccessorials.bind(this);
    this.updateWasSubmittedIncrement = this.updateWasSubmittedIncrement.bind(this);
    this.populateShipmentV4 = this.populateShipmentV4.bind(this);
    this.foremostCheckForApprovedCost = this.foremostCheckForApprovedCost.bind(this);

    this.state = {
      disabledForm: false,
      expandCardDetailsForm: false,
      chargesMessage: "",
      docsModalShow: false,
      messagesModalShow: false,
      showGenericModal: false,
      showSchuffGPSModal: false,
      schuffGPSModalTitle: "",
      schuffGPSModalBody: "",
      schuffGPSModalDisplayPhone: false,
      schuffGPSModalDisplayTruck: false,
      schuffGPSModalPhonePlaceholderText: "",
      shipmentUpdateAlert: "hide",
      docUploadedAlert: "hide",
      showHideCheckBoxForTableEditMode: "checkShipmentBoxHide",
      arrowWidth: 2,
      tableSelect: false,
      // Hilight or hide states / CSS calsses (controlled by hilightAndHideGenerator())
      // allUpdateFieldsHilightHide hides the entire update area
      allUpdateFieldsHilightHide: "hide",
      invoiceProHilightHide: "hide",
      driverPhoneHilightHide: "hide",
      truckNumberHilightHide: "hide",
      trailerNumberHilightHide: "hide",
      statusBtnsHilightHide: "hide",
      // Status buttons
      statusBtnShowHidePuOut: "hide",
      statusBtnShowHideIT: "hide",
      statusBtnShowHideITDelayed: "hide",
      statusBtnShowHideOFD: "hide",
      statusBtnShowHideDEL: "hide",
      BilledMilesEdit: "hide",
      appointmentPickupDateHilightHide: "hide",
      appointmentPickupTimeInHilightHide: "hide",
      appointmentPickupTimeOutHilightHide: "hide",
      appointmentDeliveryDateHilightHide: "hide",
      appointmentDeliveryTimeInHilightHide: "hide",
      appointmentDeliveryTimeOutHilightHide: "hide",
      actualPickupDateHilightHide: "hide",
      actualPickupTimeInHilightHide: "hide",
      actualPickupTimeOutHilightHide: "hide",
      actualDeliveryDateHilightHide: "hide",
      actualDeliveryTimeInHilightHide: "hide",
      actualDeliveryTimeOutHilightHide: "hide",
      billedCostHilightHide: "hide",
      chargesMessageHilightHide: "hide",
      signedByHilightHide: "hide",
      messagesHilightHide: "hide",
      documentsHilightHide: "hide",
      settlementIDHilightHide: "hide",
      paymentStatusHide: "hide",
      settlementDateHilightHide: "hide",
      invoiceNumberHilightHide: "hide",
      accessorialIconsHilightHide: "hide",
      // Accept decline tender & accept decline load changes
      acceptTenderShowHide: "hide",
      acceptLoadChanges: "hide",
      // Update Shipment Data Button / Provide Updates text
      updateShipmentDataBtn: "hide",
      rateAgreementTopHilightHide: "hide",
      pickupRequestTopHilightHide: "hide",
      scheduleAppointmentHilightHide: "hide",
      updateAlertStatus: "hide",
      invoiceNumber2HilightHide: "hide",
      guaranteedDeliveryIconHilightHide: "hide",
      messagesIcon: "hide",
      invoiceDateHilightHide: "hide",
      billingThridPartyCollectHilightHide: "hide",
      estimatedDeliveryDate: "hide",
      carrierQuoteIdHilightHide: "hide",
      numMessages: 0,
      numUnreadMessages: 0,
      updatedFormData: "",
      documentUploadFile: null,
      documentUploadFileName: null,
      documentUploadFileType: null,
      acceptDeclineStatusState: 'none',
      // New accessorial Reuests
      newAccesorialRequests: [],
      billedChargesPending: false,
      dockSchedulerQualification: false,
      updateWasSubmitted: 1,
      shipmentV4: null,
      tenderAcceptDeclineButtonsDisabled: false,
      inputFieldsReadOnly: false
    };
  }

  componentDidMount() {
    // console.log('uc', this.context.clientCode)
    this.startUpAndUpdateLogic("propsUpdate");
    this.hashForTableEditCheckboxID();
  }

  componentWillUnmount() {
    // Need to clear the interval or it will continue to run even when the component is unmounted
    this.setState({ updateAlertStatus: "hide" });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log(
    //   "debug shipmentcard2 compdidupdate props.shipment",
    //   this.props.shipment,
    //   "prevProps",
    //   prevProps.shipment
    // );
    if (this.props.shipment !== prevProps.shipment) {
      // console.log("debug shipmentcard2 compdidupdate props check");
      this.startUpAndUpdateLogic("propsUpdate");
    }
    if (this.props.tableEditMode !== prevProps.tableEditMode) {
      this.tableEditModeOnOffCard();
    }
  }

  incrementUpdateWasSubmitted() {
    this.setState({ updateWasSubmitted: this.state.updateWasSubmitted + 1 })
  }

  clearAddedAccessorials() {
    this.setState({ newAccesorialRequests: [] })
  }
  addAccessorialRequest(accessorial) {
    // console.log('adding accesorial', accessorial)
    let newAccesorialsRequestsArray = this.state.newAccesorialRequests
    let newAccessorial = accessorial
    newAccesorialsRequestsArray.push(newAccessorial)
    this.setState({ newAccesorialRequests: newAccesorialsRequestsArray })
  }

  copyInvoice2ToInvoicePro() {
    this.shipmentObjUpdateDirect(
      "charges.trackingNumber",
      this.state.shipment.charges.invoiceNumber
    );
  }

  // billedChargesPending() {
  //   this.setState({ billedChargesPending: true })
  // }

  checkForUnreadMessages() {
    let unreadMessages = this.props.shipment.log.filter(
      (log) =>
        log.type === "Message" && log.read === 0 && log.usercode !== "Carrier"
    );

    let messagesButAllRead = this.props.shipment.log.filter(
      (log) =>
        log.type === "Message" && log.read === 1 && log.usercode !== "Carrier"
    );

    if (this.props.shipment.log.length && messagesButAllRead.length) {
      this.setState({
        messagesIcon: "messagesNoUnread",
        numMessages: messagesButAllRead.length,
      });
    }
    if (this.props.shipment.log.length && unreadMessages.length) {
      this.setState({
        messagesIcon: "unreadMessages",
        numUnreadMessages: unreadMessages.length,
      });
    }
    if (
      this.props.shipment.log.length &&
      !unreadMessages.length &&
      !messagesButAllRead.length
    ) {
      console.log('checkForUnreadMessages', 3)
      this.setState({
        messagesIcon: "noMessages",
      });
    }

    if (!this.props.shipment.log.length) {
      this.setState({
        messagesIcon: "noMessages",
      });
    }
  }

  markMessagesReadLocally() {
    this.setState({
      numUnreadMessages: 0
    });
  }

  foremostCheckForApprovedCost(shipment) {
    // sample 158767028
    if (shipment && shipment?.charges?.approvedCost) {
      this.setState({ inputFieldsReadOnly: true })
    }
  }

  startUpAndUpdateLogic(updateType) {
    // console.log("debug startUPAndUPdateLogic fired");
    if (updateType === "propsUpdate") {
      // Setting shipmentCloneWithoutReference so that this.state.shipment doesn't have a reference to this.props.shipment and so that when you modify one, you're not modifying both since we use this.props.shipment to check if a given value has been changed or not
      const { shipment } = { ...this.props };
      let shipmentCloneWithoutReference = JSON.parse(JSON.stringify(shipment));
      this.setState({ shipment: shipmentCloneWithoutReference });
      this.populateChargeNotes();
      this.tableEditModeOnOffCard();
      this.hilightAndHideGenerator();
      this.checkForUnreadMessages();
      if (this.context.clientCode === "foremost") {
        this.foremostCheckForApprovedCost(shipment)
      }
      CheckDockSchedulerQualification(shipment).then((result) => {
        this.setState({ dockSchedulerQualification: result })
      });

      if ((shipment.customerId === "Schuff" || shipment.customerId === "Graywolf" || shipment.customerId === "graywolf") && shipment.status.statusId === "OK") {
        this.setState({ disabledForm: "disabled" })
      }
    }
  }

  checkDateSetState(date) {
    // console.log("checkDateSetState fired", date);
    if (date === "0001-01-01T00:00:00") {
      return "";
    } else {
      return date;
    }
  }

  showHilightOrHide(field) {
    if (this.props.showFields?.includes(field)) return "show";
    else if (this.props.hilightFields?.includes(field)) return "hilight";
    else return "hide";
    // allUpdateFieldsHilightHide
  }

  // This is where the hilightFields and hideFields values are processed to hilight or hide various elements on the shipment card
  hilightAndHideGenerator() {
    // Show fields
    if (this.props.showFields) {
      // Remember to include "allUpdateFieldsHilightHide" in any view that wants to update data outside accept decline shipments and offers
      // if (this.props.showFields.includes("allUpdateFieldsHilightHide")) {
      //   this.setState({ allUpdateFieldsHilightHide: "show" });
      // }
      if (this.props.showFields.includes("accessorialIconsHilightHide")) {
        this.setState({ accessorialIconsHilightHide: "show" });
      }
      if (this.props.showFields.includes("settlementIDHilightHide")) {
        this.setState({ settlementIDHilightHide: "show" });
      }
      if (this.props.showFields.includes("paymentStatus")) {
        this.setState({ paymentStatusHide: "show" });
      }
      if (this.props.showFields.includes("settlementDateHilightHide")) {
        this.setState({ settlementDateHilightHide: "show" });
      }
      if (this.props.showFields.includes("invoiceNumberHilightHide")) {
        this.setState({ invoiceNumberHilightHide: "show" });
      }
      if (this.props.showFields.includes("loadChangesAcceptDecline")) {
        this.setState({ acceptLoadChanges: "show" });
      }
      if (this.props.showFields.includes("tenderAcceptDecline")) {
        this.setState({ acceptTenderShowHide: "show" });
      }
      if (this.props.showFields.includes("invoicePro")) {
        this.setState({ invoiceProHilightHide: "show" });
      }
      if (this.props.showFields.includes("statusBtns")) {
        this.setState({ statusBtnsHilightHide: "show" });
      }
      if (this.props.showFields.includes("truckNumber")) {
        this.setState({ truckNumberHilightHide: "show" });
      }
      if (this.props.showFields.includes("trailerNumber")) {
        this.setState({ trailerNumberHilightHide: "show" });
      }
      if (this.props.showFields.includes("driverPhone")) {
        this.setState({ driverPhoneHilightHide: "show" });
      }
      if (this.props.showFields.includes("appointmentPickupDate")) {
        this.setState({ appointmentPickupDateHilightHide: "show" });
      }
      if (this.props.showFields.includes("appointmentPickupTimeIn")) {
        this.setState({ appointmentPickupTimeInHilightHide: "show" });
      }
      if (this.props.showFields.includes("appointmentPickupTimeOut")) {
        this.setState({ appointmentPickupTimeOutHilightHide: "show" });
      }
      if (this.props.showFields.includes("appointmentDeliveryDate")) {
        this.setState({ appointmentDeliveryDateHilightHide: "show" });
      }
      if (this.props.showFields.includes("appointmentDeliveryTimeIn")) {
        this.setState({ appointmentDeliveryTimeInHilightHide: "show" });
      }
      if (this.props.showFields.includes("appointmentDeliveryTimeOut")) {
        this.setState({ appointmentDeliveryTimeOutHilightHide: "show" });
      }
      if (this.props.showFields.includes("actualPickupDate")) {
        this.setState({ actualPickupDateHilightHide: "show" });
      }
      if (this.props.showFields.includes("actualPickupTimeIn")) {
        this.setState({ actualPickupTimeInHilightHide: "show" });
      }
      if (this.props.showFields.includes("actualPickupTimeOut")) {
        this.setState({ actualPickupTimeOutHilightHide: "show" });
      }
      if (this.props.showFields.includes("actualDeliveryDate")) {
        this.setState({ actualDeliveryDateHilightHide: "show" });
      }
      if (this.props.showFields.includes("actualDeliveryTimeIn")) {
        this.setState({ actualDeliveryTimeInHilightHide: "show" });
      }
      if (this.props.showFields.includes("actualDeliveryTimeOut")) {
        this.setState({ actualDeliveryTimeOutHilightHide: "show" });
      }
      if (this.props.showFields.includes("billedCost")) {
        this.setState({ billedCostHilightHide: "show" });
      }
      if (this.props.showFields.includes("chargesMessage")) {
        this.setState({ chargesMessageHilightHide: "show" });
      }
      if (this.props.showFields.includes("signedBy")) {
        this.setState({ signedByHilightHide: "show" });
      }
      if (this.props.showFields.includes("messages")) {
        this.setState({ messagesHilightHide: "show" });
      }
      if (this.props.showFields.includes("documents")) {
        this.setState({ documentsHilightHide: "show" });
      }
      if (this.props.showFields.includes("updateShipmentDataBtn")) {
        this.setState({ updateShipmentDataBtn: "show" });
      }
      // Status buttons
      if (this.props.showFields.includes("statusBtnShowHidePuOut")) {
        this.setState({ statusBtnShowHidePuOut: "show" });
      }
      if (this.props.showFields.includes("statusBtnShowHideIT")) {
        this.setState({ statusBtnShowHideIT: "show" });
      }
      if (this.props.showFields.includes("statusBtnShowHideITDelayed")) {
        this.setState({ statusBtnShowHideITDelayed: "show" });
      }
      if (this.props.showFields.includes("statusBtnShowHideOFD")) {
        this.setState({ statusBtnShowHideOFD: "show" });
      }
      if (this.props.showFields.includes("statusBtnShowHideDEL")) {
        this.setState({ statusBtnShowHideDEL: "show" });
      }
      if (this.props.showFields.includes("rateAgreementTopHilightHide")) {
        this.setState({ rateAgreementTopHilightHide: "show" });
      }
      if (this.props.showFields.includes("pickupRequestTopHilightHide")) {
        this.setState({ pickupRequestTopHilightHide: "show" });
      }
      if (this.props.showFields.includes("scheduleAppointmentHilightHide")) {
        this.setState({ scheduleAppointmentHilightHide: "show" });
      }
      if (this.props.showFields.includes("invoiceNumber2HilightHide")) {
        this.setState({ invoiceNumber2HilightHide: "show" });
      }
      if (this.props.showFields.includes("guaranteedDeliveryIconHilightHide")) {
        this.setState({ guaranteedDeliveryIconHilightHide: "show" });
      }
      if (this.props.showFields.includes("invoiceDateHilightHide")) {
        this.setState({ invoiceDateHilightHide: "show" });
      }
      if (this.props.showFields.includes("BilledMilesEdit")) {
        this.setState({ BilledMilesEdit: "show" });
      }
      if (
        this.props.showFields.includes("billingThridPartyCollectHilightHide")
      ) {
        this.setState({ billingThridPartyCollectHilightHide: "show" });
      }
    }
    // Hilight Fields
    if (this.props.hilightFields) {
      if (this.props.hilightFields.includes("loadChangesAcceptDecline")) {
        this.setState({ acceptLoadChanges: "hilight" });
      }

      if (this.props.hilightFields.includes("accessorialIconsHilightHide")) {
        this.setState({ accessorialIconsHilightHide: "hilight" });
      }

      if (this.props.hilightFields.includes("settlementIDHilightHide")) {
        this.setState({ settlementIDHilightHide: "hilight" });
      }
      if (this.props.hilightFields.includes("settlementDateHilightHide")) {
        this.setState({ settlementDateHilightHide: "hilight" });
      }
      if (this.props.showFields.includes("invoiceNumberHilightHide")) {
        this.setState({ invoiceNumberHilightHide: "hilight" });
      }
      if (this.props.hilightFields.includes("tenderAcceptDecline")) {
        this.setState({ acceptTenderShowHide: "hilight" });
      }
      if (this.props.hilightFields.includes("invoicePro")) {
        this.setState({ invoiceProHilightHide: "hilight" });
      }
      if (this.props.hilightFields.includes("driverPhone")) {
        this.setState({ driverPhoneHilightHide: "hilight" });
      }
      if (this.props.hilightFields.includes("truckNumber")) {
        this.setState({ truckNumberHilightHide: "hilight" });
      }
      if (this.props.hilightFields.includes("trailerNumber")) {
        this.setState({ trailerNumberHilightHide: "hilight" });
      }
      if (this.props.hilightFields.includes("statusBtns")) {
        this.setState({ statusBtnsHilightHide: "hilight" });
      }
      if (this.props.hilightFields.includes("appointmentPickupDate")) {
        this.setState({ appointmentPickupDateHilightHide: "hilight" });
      }
      if (this.props.hilightFields.includes("appointmentPickupTimeIn")) {
        this.setState({ appointmentPickupTimeInHilightHide: "hilight" });
      }
      if (this.props.hilightFields.includes("appointmentPickupTimeOut")) {
        this.setState({ appointmentPickupTimeOutHilightHide: "hilight" });
      }
      if (this.props.hilightFields.includes("appointmentDeliveryDate")) {
        this.setState({ appointmentDeliveryDateHilightHide: "hilight" });
      }
      if (this.props.hilightFields.includes("appointmentDeliveryTimeIn")) {
        this.setState({ appointmentDeliveryTimeInHilightHide: "hilight" });
      }
      if (this.props.hilightFields.includes("appointmentDeliveryTimeOut")) {
        this.setState({ appointmentDeliveryTimeOutHilightHide: "hilight" });
      }
      if (this.props.hilightFields.includes("actualPickupDate")) {
        this.setState({ actualPickupDateHilightHide: "hilight" });
      }
      if (this.props.hilightFields.includes("actualPickupTimeIn")) {
        this.setState({ actualPickupTimeInHilightHide: "hilight" });
      }
      if (this.props.hilightFields.includes("actualPickupTimeOut")) {
        this.setState({ actualPickupTimeOutHilightHide: "hilight" });
      }
      if (this.props.hilightFields.includes("actualDeliveryDate")) {
        this.setState({ actualDeliveryDateHilightHide: "hilight" });
      }
      if (this.props.hilightFields.includes("actualDeliveryTimeIn")) {
        this.setState({ actualDeliveryTimeInHilightHide: "hilight" });
      }
      if (this.props.hilightFields.includes("actualDeliveryTimeOut")) {
        this.setState({ actualDeliveryTimeOutHilightHide: "hilight" });
      }
      if (this.props.hilightFields.includes("billedCost")) {
        this.setState({ billedCostHilightHide: "hilight" });
      }
      if (this.props.hilightFields.includes("chargesMessage")) {
        this.setState({ chargesMessageHilightHide: "hilight" });
      }
      if (this.props.hilightFields.includes("signedBy")) {
        this.setState({ signedByHilightHide: "hilight" });
      }
      if (this.props.hilightFields.includes("messages")) {
        this.setState({ messagesHilightHide: "hilight" });
      }
      if (this.props.hilightFields.includes("documents")) {
        this.setState({ documentsHilightHide: "hilight" });
      }
      if (this.props.hilightFields.includes("updateShipmentDataBtn")) {
        this.setState({ updateShipmentDataBtn: "hilight" });
      }
      if (this.props.hilightFields.includes("scheduleAppointmentHilightHide")) {
        this.setState({ scheduleAppointmentHilightHide: "hilight" });
      }
      if (this.props.hilightFields.includes("invoiceNumber2HilightHide")) {
        this.setState({ invoiceNumber2HilightHide: "hilight" });
      }
      if (
        this.props.hilightFields.includes("guaranteedDeliveryIconHilightHide")
      ) {
        this.setState({ guaranteedDeliveryIconHilightHide: "hilight" });
      }
      if (this.props.hilightFields.includes("invoiceDateHilightHide")) {
        this.setState({ invoiceDateHilightHide: "hilight" });
      }
      if (
        this.props.hilightFields.includes("billingThridPartyCollectHilightHide")
      ) {
        this.setState({
          billingThridPartyCollectHilightHide: "hilight",
        });
      }
    }
  }

  // For Docs modal
  onHideDocs() {
    this.setState({ docsModalShow: false });
  }

  modalOpenDocs(event) {
    event.preventDefault();
    this.setState({ docsModalShow: true });
  }

  // For MessagesModal
  modalOpenMessaging(event) {
    event.preventDefault();
    this.setState((prevState) => ({
      messagesModalShow: !prevState.messagesModalShow,
    }));
    this.setState({ messagesModalShow: true });
    this.markMessageReadOnOpen();
  }

  onHideMessages() {
    this.setState({ messagesModalShow: false });
  }

  // Accept Shipment
  async handleAcceptShipment() {
    let shipmentId = this.props.shipment.id
    let macroPointMessage = 'Please make sure to use our MPID 1071335 to send us GPS tracking data. Enter our BOL #: ' + shipmentId + ' as the load identifier.'
    //  this.setAndShowGenericModal('Title', 'Body');
    if (this.context && this.context.clientCode === "Schuff" && this.context.hasOwnProperty("gpsTracking") && !!this.props.shipment.gpsTracking && this.props.shipment.gpsTracking.length > 0) {
      if (this.context.gpsTracking === null) {

        this.setAndShowSchuffGPSModal('GPS Phone Tracking Notice', "If you have the driver's cell phone number please fill out the field below.  If you do not have the phone number you can update this later on the Carrier Portal.  The Driver will then receive a text message soon to track the load with Macropoint GPS Tracking.", true, "Driver's Cell Phone Number (no dashes)", false);
      }
      if (this.context.gpsTracking === 'MacroPoint') {
        if (this.context.gpsMethod === 'Telematics') {
          macroPointMessage = "Our system will put in a tracking request to connect directly with ELD/Telematics system once the Truck Number is entered."
          this.setAndShowSchuffGPSModal('GPS Macropoint Tracking Notice', macroPointMessage, true, "Enter Phone Number here if Cell Phone Tracking Needed instead of Direct ELD/TMS Connection", true);
        } else {
          this.setAndShowSchuffGPSModal('GPS Macropoint Tracking Notice', macroPointMessage, true, "Enter Phone Number if Cell Phone Tracking Needed instead of Direct ELD/TMS Connection", false);
        }

      }
    }
    let userEmail = ""
    if (this.context) {
      userEmail = this.context.email
    }
    let userName = ""
    if (this.context) {
      userName = this.context.name
    }
    this.setState({ acceptDeclineStatusState: "loading" });
    let result = await AcceptShipment.AcceptShipment(this.props.shipment.id, this.props.shipment.loadDetails.secureId, userEmail, userName);
    console.log('handleAcceptShipment result', result)
    if (result.response === "success") {
      this.setState({ acceptDeclineStatusState: "success" });
      this.setState({ tenderAcceptDeclineButtonsDisabled: true })
    } else {
      this.setState({ acceptDeclineStatusState: "error" });
      this.setState({ tenderAcceptDeclineButtonsDisabled: false })
    }
    if (this.showHilightOrHide('sendPUEmailOnAccept') === "show") {
      SendEmail(this.props.shipment, this.context)
    }

  }

  //Decline Shipment
  async handleDeclineShipment() {
    let userEmail = ""
    if (this.context) {
      userEmail = encodeURIComponent(this.context.email)
    }
    let userName = ""
    if (this.context) {
      userName = encodeURIComponent(this.context.name)
    }
    this.setState({ acceptDeclineStatusState: "loading" });
    let result = await DeclineShipment.DeclineShipment(this.props.shipment.id, this.props.shipment.loadDetails.secureId, userEmail, userName)
    if (result.response === "success") {
      this.setState({ acceptDeclineStatusState: "success" });
      this.setState({ tenderAcceptDeclineButtonsDisabled: true })
    } else {
      this.setState({ acceptDeclineStatusState: "error" });
      this.setState({ tenderAcceptDeclineButtonsDisabled: false })
    }
    if (this.showHilightOrHide('sendEmailOnDecline') === "show") {
      SendDeclineShipmentEmail(this.props.shipment, this.context)
    }

  }

  // Accept Load Changes
  handleAcceptShipmentChanges() {
    MessagesRead.MarkAllMessagesRead(this.props.shipment.id);
    this.setState({ acceptDeclineStatusState: "success" });
  }

  // Decline Load Changes
  handleDeclineShipmentChanges() {
    DeclineShipment.DeclineShipment(this.props.shipment.id, this.props.shipment.loadDetails.secureId);
    MessagesRead.MarkAllMessagesRead(this.props.shipment.id);
    this.setState({ acceptDeclineStatusState: "success" });
  }

  //Mark as read (move this to the api folder later)
  markMessageReadOnOpen() {
    axios
      .get(
        "https://www2.shippersedge.com/public/carrier/markAllMessagesRead.cfm?wsdl&i=" +
        this.props.shipment.id
      )
      .then((response) => {

      })
      .catch((error) => {
        if (error) {
          console.warn(
            "ShipmentCard2.js, error on markMessageReadOnOpen:",
            error
          );
        }
      });
  }

  //
  // New documentUpload upload functions
  //
  documentUploadFileChangeHandler(event) {
    // console.log("newFileChange", event.target.files[0]);
    // console.log("newFileChange name", event.target.files[0].name);
    this.setState({
      documentUploadFileName: event.target.files[0].name,
      documentUploadFile: event.target.files[0],
    });
  }
  documentUploadHandleChangeDocSelect(event) {
    // console.log(event.target.value);
    this.setState({ documentUploadFileType: event.target.value });
  }

  documentUploadBuildFormData() {
    return new Promise((resolve) => {
      const uploadData = new FormData();
      uploadData.append("shipmentId", this.state.shipment.id.toString());
      uploadData.append("fileType", this.state.documentUploadFileType);
      uploadData.append(
        "file",
        this.state.documentUploadFile,
        this.state.documentUploadFileType
      );
      uploadData.append("contactEmail", Authentication.getEmailAddress());
      resolve(uploadData);
    });
  }

  setAndShowGenericModal(genericModalTitle, genericModalBody) {
    this.setState({
      genericModalTitle: genericModalTitle,
      genericModalBody: genericModalBody,
      showGenericModal: true,
    });
  }


  setAndShowSchuffGPSModal(title, body, phone, phonePlaceholderText, truck) {
    this.setState({
      schuffGPSModalTitle: title,
      schuffGPSModalBody: body,
      schuffGPSModalDisplayPhone: phone,
      showSchuffGPSModal: true,
      schuffGPSModalPhonePlaceholderText: phonePlaceholderText,
      schuffGPSModalDisplayTruck: truck
    });
  }




  hideSchuffGPSModal() {
    this.setState({
      showSchuffGPSModal: false,
      schuffGPSModalTitle: "default text",
      schuffGPSModalBody: "default text",
      schuffGPSModalDisplayPhone: false,
      schuffGPSModalPhonePlaceholderText: "default text",
      schuffGPSModalDisplayTruck: false
    });
  }

  handleSubmitUpload(event) {
    event.preventDefault();

    const uploadData = new FormData();
    uploadData.append("shipmentId", this.shipmentID.value.toString());
    uploadData.append("fileType", this.state.fileType);
    uploadData.append(
      "file",
      this.fileInput.current.files[0],
      this.state.fileType
    );
    uploadData.append("contactEmail", Authentication.getEmailAddress());
    UploadFile.Upload(uploadData); // need to add some callback here for the promise, maybelook for "tripped up and etc"
    setTimeout(this.localUpDateSingleShipment, 3000);
    this.setState({ docUploadedAlert: "show" });
  }

  // This is a generic change handler that will set state based on the "name" value of the html element and set the value in state, this should work for most fields
  handleChangeGeneric(event) {
    event.preventDefault();
    // console.log(
    //   "handleChangeGeneric",
    //   event.target.name,
    //   "-",
    //   event.target.value
    // );
    this.setState({ [event.target.name]: event.target.value });
  }

  shipmentObjUpdateEvent(event, newValue = null) {
    console.log('event', event.target)
    event.preventDefault();
    const { name, value: eventValue } = event.target;
    const value = newValue || eventValue;
    const { shipment } = { ...this.state };
    const splitOutput = name.split(".");
    const newState = shipment;
    function setDeep(obj, path, value, setrecursively = false) {
      let level = 0;
      path.reduce((a, b) => {
        level++;
        if (
          setrecursively &&
          typeof a[b] === "undefined" &&
          level !== path.length
        ) {
          a[b] = {};
          return a[b];
        }
        if (level === path.length) {
          a[b] = value;
          return value;
        } else {
          return a[b];
        }
      }, obj);
    }
    setDeep(newState, splitOutput, value);

    this.setState({ shipment: newState });
  }

  shipmentObjUpdateDirect(path, value) {
    const { shipment } = { ...this.state };
    const splitOutput = path.split(".");
    const newState = shipment;
    function setDeep(obj, path, value, setrecursively = false) {
      let level = 0;
      path.reduce((a, b) => {
        level++;
        if (
          setrecursively &&
          typeof a[b] === "undefined" &&
          level !== path.length
        ) {
          a[b] = {};
          return a[b];
        }
        if (level === path.length) {
          a[b] = value;
          return value;
        } else {
          return a[b];
        }
      }, obj);
    }
    setDeep(newState, splitOutput, value);

    this.setState({ shipment: newState });
  }

  // Search the messages log for any charges messages (anything that includes "ChargesNote: ")
  populateChargeNotes() {
    const chargesMessages = this.props.shipment.log.filter(
      (item) =>
        item?.type === "Message" && item?.entryDetails?.includes("ChargesNote: ")
    );
    // Need to account for null so we don't populate all charges notes with null if the DB returns that.
    if (
      chargesMessages.length &&
      chargesMessages[0].entryDetails !== "ChargesNote: null"
    ) {
      const chargesMessageSorted = chargesMessages.sort((a, b) => b.id - a.id);
      this.setState({
        chargesMessage: chargesMessageSorted[0].entryDetails.slice(13),
      });
    } else {
      this.setState({
        chargesMessage: "",
      });
    }
  }

  // A check to see if billedCost and esttimatedCost are the same, if not pop up a modal asking them to fill out the Charges Notes: if they haven't already
  checkBilledVsEstCost() {
    // console.log("checkBilledVsEstCost fired", parseFloat(this.state.billedCost), parseFloat(this.props.shipment.charges.estimatedCost));
    if (
      parseFloat(this.state.shipment.charges.billedCost) ===
      parseFloat(this.props.shipment.charges.estimatedCost)
    ) {
      return true;
    } else if (
      this.state.chargesMessage === null &&
      this.state.shipment.charges.billedCost !== ""
    ) {
      // console.log("checkBilledVsEstCost chargesMessage Null");
      this.setState({
        genericModalTitle: "Billed Cost Is Different Than Estimated Cost",
        genericModalBody:
          "If the Billed Cost is different than the Estimated Cost please add a note as to why in the 'Charges Notes' field next to Billed Charges.  Thank you.",
      });
      this.genericModalShow();
      return false;
    } else {
      return true;
    }
  }

  // The check at the start of this is to make sure we don't resubmit the same message value each time someone submits a form, if the value is the same, doesn't submit
  // This uses the messages system to handle charges messages (in the past it used a field in carrier settlement, now using messages)
  checkAndSubmitChargesNotes() {
    console.log("checkAndSubmitChargesNotes fired");
    // console.log("the existing value", this.state.chargesMessage)
    let checkPropsMessages = [];
    checkPropsMessages = this.props.shipment.log.filter(
      (item) =>
        item.type === "Message" && item.entryDetails.includes("ChargesNote: ")
    );
    // console.log("checkAndSubmitChargesNotes()", checkPropsMessages[0].entryDetails.slice(13))
    if (checkPropsMessages.length) {
      // Had a few occurances where null turned into "null" text and repeated messages, will change this when Shipmentcard2 gets an overhaul.   Until then the below filter duplicate or unwanted values.
      if (
        this.state.chargesMessage ===
        checkPropsMessages[0].entryDetails.slice(13) ||
        this.state.chargesMessage === "Reason for charges here" ||
        this.state.chargesMessage === null ||
        this.state.chargesMessage === "null" ||
        this.state.chargesMessage === "ChargesNote: null"
      ) {
        // console.log("checkAndSubmitChargesNotes() caught unwanted chargesNotes value:", this.state.chargesMessage)
        return null;
      } else {
        //If the billed charges note is a new Message
        const prependedMsg = "ChargesNote: " + this.state.chargesMessage;
        const messageData = new FormData();
        messageData.append("shipmentId", this.props.shipment.id.toString());
        messageData.append("usercode", "Carrier");
        messageData.append("contactEmail", Authentication.getEmailAddress());
        messageData.append("message", prependedMsg);
        SendMessage.Send(messageData);
      }
    } else {
      // Had a few occurances where null turned into "null" text and repeated messages, will change this when Shipmentcard2 gets an overhaul.   Until then the below filter duplicate or unwanted values.
      if (
        this.state.chargesMessage === "Reason for charges here" ||
        this.state.chargesMessage === null ||
        this.state.chargesMessage === "null" ||
        this.state.chargesMessage === "ChargesNote: null"
      ) {
        // console.log("checkAndSubmitChargesNotes() caught unwanted chargesNotes value:", this.state.chargesMessage)
        return null;
      } else {
        // console.log("null hits here 2");
        //If no billed charges notes exist
        // console.log("checkAndSubmitChargesNotes last else");
        const prependedMsg = "ChargesNote: " + this.state.chargesMessage;
        const messageData = new FormData();
        messageData.append("shipmentId", this.props.shipment.id.toString());
        messageData.append("usercode", "Carrier");
        messageData.append("contactEmail", Authentication.getEmailAddress());
        messageData.append("message", prependedMsg);
        SendMessage.Send(messageData);
      }
    }
  }

  // Gould wants to be sure that if there is a billed cost entered then there must be a pro/tracking number
  // Eventualy need to figureo ut a way to abstract this sort of customer specific checking another way
  checkProPlusInvoice() {
    if (
      localStorage.getItem("carrierClientId") === "gould" &&
      this.state.shipment.charges.billedCost !==
      this.props.shipment.charges.billedCost &&
      (this.state.shipment.charges.trackingNumber === null ||
        this.state.shipment.charges.trackingNumber === "")
    ) {
      return false;
    } else {
      return true;
    }
  }

  buildFormDataForShipmentUpdate() {
    return new Promise((resolve) => {
      // console.log("buildFormDataForShipmentUpdate");
      const updateData = new FormData();
      // shipmentID, contactEmail, status are required for an update to work
      updateData.append("shipmentID", this.props.shipment.id);
      updateData.append("contactEmail", Authentication.getEmailAddress());
      updateData.append("status", this.state.shipment.status.statusId);
      //
      if (
        this.state.shipment.charges.invoiceNumber !==
        this.props.shipment.charges.invoiceNumber
      ) {
        updateData.append(
          "invoiceNum",
          this.state.shipment.charges.invoiceNumber
        );
      }

      // Carrier Nmae
      if (
        this.state.shipment.carrierName !==
        this.props.shipment.carrierName
      ) {
        updateData.append(
          "carrierName",
          this.state.shipment.carrierName
        );
      }
      // Driver phone
      if (
        this.state.shipment.loadDetails.driverPhone !==
        this.props.shipment.loadDetails.driverPhone
      ) {
        updateData.append(
          "driver_phone",
          this.state.shipment.loadDetails.driverPhone
        );
      }

      // Truck Number
      if (
        this.state.shipment.loadDetails.truckNumber !==
        this.props.shipment.loadDetails.truckNumber
      ) {
        updateData.append(
          "truck_number",
          this.state.shipment.loadDetails.truckNumber
        );
      }

      // Trailer Number
      if (
        this.state.shipment.loadDetails.trailerNumber !==
        this.props.shipment.loadDetails.trailerNumber
      ) {
        updateData.append(
          "trailer_number",
          this.state.shipment.loadDetails.trailerNumber
        );
      }

      if (
        (this.state.invoiceDate && DateChecker(this.state.invoiceDate)) ||
        this.state.invoiceDate === ""
      ) {
        updateData.append("invdate", this.state.invoiceDate);
      }
      if (
        this.state.shipment.charges.trackingNumber !==
        this.props.shipment.charges.trackingNumber
      ) {
        // console.log(
        //   "debug state.trackingNumber",
        //   this.state.shipment.charges.trackingNumber,
        //   "debug props trackingNumber",
        //   this.props.shipment.charges.trackingNumber
        // );
        updateData.append("pronum", this.state.shipment.charges.trackingNumber);
      }

      // Appointment Pickup:
      // Appointment Pickup Date
      if (
        this.state.shipment.dates.appointmentPickupDate !==
        this.props.shipment.dates.appointmentPickupDate
      )
        updateData.append(
          "apptPUDate",
          this.state.shipment.dates.appointmentPickupDate
        );
      // Appointment Pickup Time In
      if (
        this.state.shipment.dates.appointmentPickupTimeIn !==
        this.props.shipment.dates.appointmentPickupTimeIn
      )
        updateData.append(
          "apptPUInTime",
          moment(
            this.state.shipment.dates.appointmentPickupTimeIn,
            "HH:mm"
          ).format("HH:mm:ss")
        );
      // Appointment Pickup Time Out
      if (
        this.state.shipment.dates.appointmentPickupTimeOut !==
        this.props.shipment.dates.appointmentPickupTimeOut
      )
        updateData.append(
          "apptPUOutTime",
          moment(
            this.state.shipment.dates.appointmentPickupTimeOut,
            "HH:mm"
          ).format("HH:mm:ss")
        );

      // Actual Pickup:
      // Actual Pickup Date
      // if (1 === 1
      // this.state.shipment.dates.pickupDate !==
      // this.props.shipment.dates.pickupDate
      // )
      updateData.append("pudate", this.state.shipment.dates.pickupDate);
      // Actual Pickup Time In
      if (
        this.state.shipment.dates.pickupTimeIn !==
        this.props.shipment.dates.pickupTimeIn
      )
        updateData.append(
          "puInTime",
          moment(this.state.shipment.dates.pickupTimeIn, "HH:mm:").format(
            "HH:mm:ss"
          )
        );
      // Actual Pickup Time Out
      if (
        this.state.shipment.dates.pickupTimeOut !==
        this.props.shipment.dates.pickupTimeOut
      )
        updateData.append(
          "puOutTime",
          moment(this.state.shipment.dates.pickupTimeOut, "HH:mm:").format(
            "HH:mm:ss"
          )
        );

      // Estimated Delivery Date:
      // masterquotes.estdeldate
      if (
        this.state.shipment.dates.estimatedDeliveryDate !==
        this.props.shipment.dates.estimatedDeliveryDate
      ) {
        updateData.append(
          "estimatedDeliveryDate",
          this.state.shipment.dates.estimatedDeliveryDate
        );
      }

      if (
        this.state.shipment.dates.estimatedDelivery !==
        this.props.shipment.dates.estimatedDelivery
      ) {
        updateData.append(
          "estimatedDeliveryDate",
          this.state.shipment.dates.estimatedDelivery
        );
      }

      // Estimated Pickup
      // masterquotes.estpudate
      // V2 Shipment object shipment.dates.estimatedPickup
      if (
        this.state.shipment.dates.estimatedPickup !==
        this.props.shipment.dates.estimatedPickup
      ) {
        updateData.append(
          "estpudate",
          this.state.shipment.dates.estimatedPickup
        );
      }

      // masterquotes.estputime
      // V2 Shipment object shipment.dates.estimatedPickupTime
      if (
        this.state.shipment.dates.estimatedPickupTime !==
        this.props.shipment.dates.estimatedPickupTime
      ) {
        updateData.append(
          "estputime",
          this.state.shipment.dates.estimatedPickupTime
        );
      }

      // masterquotes.estputime
      // V2 Shipment object shipment.dates.estimatedDeliveryTime
      if (
        this.state.shipment.dates.estimatedDeliveryTime !==
        this.props.shipment.dates.estimatedDeliveryTime
      ) {
        updateData.append(
          "estdeltime",
          this.state.shipment.dates.estimatedDeliveryTime
        );
      }



      // Appointment Delivery:
      // Appointment Delivery Date
      if (
        this.state.shipment.dates.appointmentDeliveryDate !==
        this.props.shipment.dates.appointmentDeliveryDate
      )
        updateData.append(
          "apptDelDate",
          this.state.shipment.dates.appointmentDeliveryDate
        );
      // Appointment Delivery Time In
      if (
        this.state.shipment.dates.appointmentDeliveryTimeIn !==
        this.props.shipment.dates.appointmentDeliveryTimeIn
      )
        updateData.append(
          "apptDelInTime",
          moment(
            this.state.shipment.dates.appointmentDeliveryTimeIn,
            "HH:mm:"
          ).format("HH:mm:ss")
        );

      // Appointment Delivery Time Out
      if (
        this.state.shipment.dates.appointmentDeliveryTimeOut !==
        this.props.shipment.dates.appointmentDeliveryTimeOut
      )
        updateData.append(
          "apptDelOutTime",
          moment(
            this.state.shipment.dates.appointmentDeliveryTimeOut,
            "HH:mm:"
          ).format("HH:mm:ss")
        );

      // Actual Delivery:
      // Actual Delivery Date
      if (
        this.state.shipment.dates.deliveryDate !==
        this.props.shipment.dates.deliveryDate
      )
        updateData.append("deldate", this.state.shipment.dates.deliveryDate);
      // Actual Delivery Time In
      if (
        this.state.shipment.dates.deliveryTimeIn !==
        this.props.shipment.dates.deliveryTimeIn
      )
        updateData.append(
          "delInTime",
          moment(this.state.shipment.dates.deliveryTimeIn, "HH:mm").format(
            "HH:mm:ss"
          )
        );
      // Actual Delivery Time Out
      if (
        this.state.shipment.dates.deliveryTimeOut !==
        this.props.shipment.dates.deliveryTimeOut
      )
        updateData.append(
          "delOutTime",
          moment(this.state.shipment.dates.deliveryTimeOut, "HH:mm").format(
            "HH:mm:ss"
          )
        );

      // Signed By
      if (this.state.shipment.signedBy !== this.props.shipment.signedBy)
        updateData.append("signedBy", this.state.shipment.signedBy);
      // Billed cost
      if (
        !this.props.showFields.includes("billedCostDetails") &&
        this.state.shipment.charges.billedCost !==
        this.props.shipment.charges.billedCost
      )
        updateData.append(
          "billedCost",
          this.state.shipment.charges.billedCost.toString()
        );

      // Debug FormData
      // for (var pair of updateData.entries()) {
      //   console.log("debug FormData", pair[0] + ", " + pair[1]);
      // }

      // Submit data to API:

      // UpdateShipment.UpdateStatus(updateData);
      // UpdateShipmentStatus.UpdateShipmentStatus2(updateData);
      resolve(updateData);
      //add callback for some level of verification
      // Need a time out here as the database sometimes isn't updated / return the updated data submitted in UpDateStatus if we call for the shipment data from the shipment API.  Probabbly a Coldfusion vs Node API speed thing
      // setTimeout(this.localUpDateSingleShipment, 3000);
      // Confirmation message that updates were sent
      // setTimeout(this.showUpdateAlert, 5000);
      // this.setState({ shipmentUpdateAlert: "show" });
    });
  }

  localUpDateSingleShipment() {
    let bol = this.props.shipment.id;
    this.props.updateSingleShipment(bol);
  }

  showUpdateAlert() {
    this.setState({ updateAlertStatus: "show" });
  }

  setShipmentStatus(event) {
    // console.log("setShipmentStatus fired");
    this.setState({ shipmentStatus: event.target.name });
  }

  genericModalShow() {
    this.setState({
      showGenericModal: true,
    });
  }

  genericModalHide() {
    this.setState({
      showGenericModal: false,
      genericModalTitle: "Default Text",
      genericModalBody: "Default Text",
    });
  }

  // For show / hide checkboxes for group updates / table view updates
  tableEditModeOnOffCard() {
    if (this.props.tableEditMode) {
      this.setState({
        showHideCheckBoxForTableEditMode: "checkShipmentBoxShow",
        arrowWidth: 1,
      });
    } else {
      this.setState({
        showHideCheckBoxForTableEditMode: "checkShipmentBoxHide",
        arrowWidth: 2,
      });
    }
  }

  tableEditCheckEvent(event) {
    let shipmentId = event.target.value;
    this.setState(
      (prevState) => ({
        tableSelect: !prevState.tableSelect,
      }),
      () => this.props.handleChangeTableItem(shipmentId, this.state.tableSelect)
    );
  }
  // This just makes a hash for the HTML ID for the checkboxes to use.   Some orders will appear multiple times and we need to differenetiate the HTML IDs purely for the purpose of not duplicating HTML IDs
  hashForTableEditCheckboxID() {
    var idHash =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    this.setState({ checkBoxIDHash: idHash });
  }


  updateWasSubmittedIncrement() {
    this.setState({ updateWasSubmitted: this.state.updateWasSubmitted + 1 })
  }



  condRenderCardBodyDetail() {
    return (
      <div>
        <Row className="cardBodyRow2">
          <Col xs={3}>
            {/* Equipment, weight, distance */}
            <SCC.LoadDetails userData={this.context} shipment={this.props.shipment} shipmentObjUpdateEvent={this.shipmentObjUpdateEvent} BilledMilesEdit={this.state.BilledMilesEdit} />
            {/* HU and HU dimensions */}
            <SCC.Packages render="show" shipment={this.props.shipment} />
            {/* Custom Field 10161 */}
            <SCC.CF10161 shipment={this.props.shipment} />
          </Col>

          <Col xs={3}>
            <SCC.PickupAddress shipment={this.props.shipment} />
          </Col>
          <Col xs={1} />
          <Col xs={3}>
            <SCC.DeliveryAddress shipment={this.props.shipment} />
          </Col>

          <Col xs={2}>

            {/* Charges */}
            {this.showHilightOrHide("RequestAccessorials") === "show" ?
              <SCC.ChargesV2 shipment={this.props.shipment} /> :
              <SCC.ChargesV1 shipment={this.state.shipment} paymentStatusHide={this.state.paymentStatusHide} shipmentV4={this.state.shipmentV4} />}



            <SCC.RateAgreementDoc shipment={this.props.shipment} render={this.state.rateAgreementTopHilightHide} />
            <SCC.PickupRequestDoc shipment={this.props.shipment} render={this.state.pickupRequestTopHilightHide} />
            <SCC.TripManifest shipment={this.props.shipment} render={this.props.showFields.includes("tripManifestTopHilightHide")} />
            <SCC.BillOfLading shipment={this.props.shipment} render={this.props.showFields.includes("billOfLadingTopHilightHide")} />
          </Col>
        </Row>

        {/* <SCC.ConsigeeDocs shipment={this.props.shipment} /> */}


        {this.state.scheduleAppointmentHilightHide !== "hide" || this.state.billingThridPartyCollectHilightHide !== "hide" ?
          <Row className="cardBodyRow3 mt-3">
            {this.state.dockSchedulerQualification ? <SCC.DockSchedulerLink
              render={this.state.scheduleAppointmentHilightHide}
              link={this.props.shipment.loadDetails.appointmentLink}
              status={this.props.shipment.status.statusId}
              pickupApptDate={this.state.shipment.dates.appointmentPickupDate}
              shipment={this.props.shipment}
            /> : null}


            <Col xs="6"> </Col>
            <Col xs="3">
              {" "}
              <SCC.BillingThirdPartyCollect
                billTo={this.props.shipment.billTo}
                render={this.state.billingThridPartyCollectHilightHide}
              />
            </Col>
          </Row>
          : null}


        <KwikTripLiveDropNotice shipment={this.props.shipment} />


        {(this.props.shipment.customerId === "Schuff" && this?.props?.shipment?.customFields && this?.props?.shipment?.customFields.length && this?.props?.shipment?.customFields.filter(customField => customField.fieldId === 119).length > 0) || this.props.shipment.shipper.bolNotes || this.props.shipment.consignee.bolNotes ?
          <Row className="mt-3">
            {this.props.shipment.customerId === "Schuff" && this?.props?.shipment?.customFields && this?.props?.shipment?.customFields.length && this?.props?.shipment?.customFields.filter(customField => customField.fieldId === 119).length > 0 ?

              <Col>
                {this?.props?.shipment?.customFields.map(shipment => {
                  if (shipment.fieldId === 119 && shipment.value !== null && shipment.value !== "") {
                    return (
                      <div className="mt-2 mb-2">
                        <span className="font-weight-bold">BOL Note:</span>{" "}
                        <span>{shipment.value}</span>
                        <br />
                      </div>

                    )
                  }
                })}
              </Col>
              : null}
            {this.props.shipment.shipper.bolNotes ? (
              <Col>
                <span className="font-weight-bold">Pickup Note:</span>{" "}
                {this.props.shipment.shipper.bolNotes}
                <br />
              </Col>
            ) : null}
          </Row>
          : null}

        {this.props.shipment.consignee.bolNotes ?
          <Row className="mt-3">
            {this.props.shipment.consignee.bolNotes ? (
              <Col>
                <div className="mt-2 mb-2">
                  <span className="font-weight-bold">Delivery Note:</span>{" "}
                  {this.props.shipment.consignee.bolNotes}
                  <br />
                </div>
              </Col>
            ) : null}
          </Row>
          : null}
        <Row>
          <Col />
        </Row>
      </div>
    );

  }

  condRenderAcceptDeclineTender() {
    if (this.state.acceptTenderShowHide !== "hide") {
      // If Dockscheduler link is present
      if (this.props.shipment.loadDetails.appointmentLink !== null && this.props.shipment.customerId !== "kissner" && this.state.dockSchedulerQualification) {
        return (
          <div>
            <Row className="mt-3">
              {/* Accept / Decline Tender buttons */}
              <Col xs lg="2" className={`mr-3 ${this.state.acceptTenderShowHide}`}>
                <a
                  href={`${this.props.shipment.loadDetails.appointmentLink}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button
                    type="button"
                    className="btn btn-outline-success btn-block"
                    onClick={this.handleAcceptShipment}
                    disabled={this.state.tenderAcceptDeclineButtonsDisabled}
                  >
                    Accept & Schedule Appointment
                  </button>
                </a>
              </Col>
              <Col xs lg="2" className={`${this.state.acceptTenderShowHide}`}>
                <button
                  type="button"
                  className="btn btn-outline-danger btn-block"
                  onClick={this.handleDeclineShipment}
                  disabled={this.state.tenderAcceptDeclineButtonsDisabled}
                >
                  Decline <br />
                  Tender
                  <br />
                  &nbsp;
                </button>
              </Col>
            </Row>
          </div>
        );
      } else {
        // If no dock scheduler link
        return (
          <div>

            <Row className="mt-3">
              {/* Accept / Decline Tender buttons */}
              <Col xs lg="2">
                <div className={`${this.state.acceptTenderShowHide}`} style={{ textAlign: 'center' }}>
                  <Button
                    variant="outline-success"
                    onClick={this.handleAcceptShipment}
                    disabled={this.state.tenderAcceptDeclineButtonsDisabled}
                  >
                    Accept Tender
                  </Button>
                </div>
              </Col>
              <Col xs lg="2">
                <div className={`${this.state.acceptTenderShowHide}`} style={{ textAlign: 'center' }}>
                  <Button
                    onClick={this.handleDeclineShipment}
                    variant="outline-danger"
                    style={{ padding: "0.5rem 0.5rem" }}
                    disabled={this.state.tenderAcceptDeclineButtonsDisabled}
                  >
                    Decline Tender
                  </Button>
                </div>
                {/*
                Send Email testing
              */}
                {/* {this.showHilightOrHide('sendPUEmailOnAccept') === "show" ?
                  <button onClick={(event) => SendEmail(this.props.shipment, this.context)}>Send Email Testing</button>
                  : null
                } */}
              </Col>
              <Col xs lg="3">
                <AcceptDeclineStatus status={this.state.acceptDeclineStatusState} />
              </Col>
            </Row>
          </div>
        );
      }
    } else {
      return null;
    }
  }

  condRenderAcceptDeclineLoadChanges() {
    if (this.state.acceptLoadChanges !== "hide") {
      return (
        <div>
          <Row className="mt-3">
            <Col xs lg="2">
              <div className={`${this.state.acceptLoadChanges}`}>
                <Button
                  variant="outline-success"
                  onClick={this.handleAcceptShipmentChanges}
                >
                  Accept Load Changes
                </Button>
              </div>
            </Col>
            <Col xs lg="2">
              <div className={`${this.state.acceptLoadChanges}`}>
                <Button
                  onClick={this.handleDeclineShipmentChanges}
                  variant="outline-danger"
                >
                  Decline Load Changes
                </Button>
              </div>
            </Col>

            {/* <Col xs lg="2" /> */}
            <Col xs lg="3">
              <AcceptDeclineStatus status={this.state.acceptDeclineStatusState} />
            </Col>
            {/* <Col xs lg="3" className={this.state.acceptDeclineUpdateAlertSuccess}>
              <Alert variant="success">Shipment Updated!</Alert>
            </Col>
            <Col xs lg="3" className={this.state.acceptDeclineUpdateAlertFailure}>
              <Alert variant="danger">Shipment NOT updated, please try again.</Alert>
            </Col> */}
          </Row>
        </div>
      );
    } else {
      return null;
    }
  }

  condRenderAllUpdateFields() {
    if (!this.props.shipment.settlementId) {
      let invoiceNumberPlaceHolderText = "If Different from Pro Number"
      if (this.context.clientCode === "kwiktrip" || this.context.clientCode.includes("halliburton")) {
        invoiceNumberPlaceHolderText = ""
      }
      return (
        <div
          className={`${this.showHilightOrHide(
            "allUpdateFieldsHilightHide"
          )} 'heylook'`}
        >
          <Form
            onSubmit={this.handleSubmitUpdateShipment}
            autoComplete="off"
          >
            {this.state.messagesIcon === "unreadMessages" ?
              <Form.Row>
                <Col>
                  <SCC.UnreadMessageBox shipment={this.props.shipment} modalOpenMessaging={this.modalOpenMessaging} markMessageReadOnOpen={this.markMessageReadOnOpen} />
                </Col>
              </Form.Row>
              : null}

            {this.props.showFields.includes("BilledCostBreakdownReadOnly") ?

              <Form.Row>

                <SCC.BilledCostBreakdownReadOnly render={this.props.showFields.includes("BilledCostBreakdownReadOnly")} userData={this.context} shipment={this.props.shipment} />

              </Form.Row>

              : null}




            {this.showHilightOrHide("RequestAccessorialsByQty") === "show" ?
              (
                <Form.Row>
                  <Col xs lg="12">
                    <SCC.AccessorialRequestsByQty userData={this.context} shipment={this.props.shipment} />
                  </Col>
                </Form.Row>
              )
              : null}


            {this.showHilightOrHide("RequestAccessorials") === "show" ?
              <Form.Row>
                <Col xs lg="12" className="mt-3">
                  <SCC.AccessorialRequest userData={this.context} newAccesorialRequests={this.state.newAccesorialRequests} addAccessorialRequest={this.addAccessorialRequest} clearAddedAccessorials={this.clearAddedAccessorials} shipment={this.props.shipment} showOrHide={this.showHilightOrHide("RequestAccessorials")} inputFieldsReadOnly={this.state.inputFieldsReadOnly} />
                </Col>
              </Form.Row>
              : null}

            {(this.state.statusBtnsHilightHide === "show" || this.state.statusBtnsHilightHide === "hilight" || this.props.showFields.includes("GPSInfo")) ?
              <Form.Row className="mt-3">
                <div class="col-auto">

                  <SCC.ShipmentStatus
                    shipment={this.state.shipment} statusBtns={this.state.statusBtnsHilightHide} shipmentObjUpdateEvent={this.shipmentObjUpdateEvent} statusBtnsHilightHide={this.state.statusBtnsHilightHide} statusBtnShowHidePuOut={this.state.statusBtnShowHidePuOut} statusBtnShowHideIT={this.state.statusBtnShowHideIT} statusBtnShowHideITDelayed={this.state.statusBtnShowHideITDelayed} statusBtnShowHideOFD={this.state.statusBtnShowHideOFD} statusBtnShowHideDEL={this.state.statusBtnShowHideDEL}
                    statusBtnShowHideScheduled={this.props.showFields.includes("statusBtnShowHideScheduled")}
                    inputFieldsReadOnly={this.state.inputFieldsReadOnly}
                  />


                </div>
                {this.props.showFields.includes("GPSInfo") ?
                  <div class="col-auto">
                    <SCC.GPSInfo shipment={this.state.shipment} />
                  </div>
                  : null}
              </Form.Row>
              : null}

            {this.showHilightOrHide("carrierNameInput") !== "hide" || this.showHilightOrHide("invoicePro") !== "hide" ?
              <Form.Row className="mt-3">
                <Col>
                  <SCC.CarrierName
                    carrierName={this.state.shipment.carrierName}
                    shipmentObjUpdateEvent={this.shipmentObjUpdateEvent}
                    showOrHide={this.showHilightOrHide("carrierNameInput")}
                  />
                  &nbsp;
                  <SCC.TrackingInvoiceNumber
                    showOrHide={this.showHilightOrHide("invoicePro")}
                    clientCode={this.context.clientCode}
                    trackingNumber={this.state.shipment.charges.trackingNumber}
                    shipmentObjUpdateEvent={this.shipmentObjUpdateEvent}
                    inputFieldsReadOnly={this.state.inputFieldsReadOnly}
                  />
                </Col>

              </Form.Row>
              : null}

            {this.state.invoiceNumber2HilightHide !== "hide" || this.state.truckNumberHilightHide !== "hide" || this.state.trailerNumberHilightHide !== "hide" || this.state.driverPhoneHilightHide !== "hide" || this.state.invoiceDateHilightHide !== "hide" ?
              <Form.Row className="mt-3">
                <Col
                  xs
                  lg="4"
                  className={`${this.state.invoiceNumber2HilightHide}`}
                >
                  {" "}
                  <Form.Label>Invoice Number:</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    name="charges.invoiceNumber"
                    value={this.state.shipment.charges.invoiceNumber}
                    onChange={this.shipmentObjUpdateEvent}
                    placeholder={invoiceNumberPlaceHolderText}
                  />{" "}
                  {this.state.shipment.charges.invoiceNumber === "" ? null : (
                    <span
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        textDecoration: "underline",
                      }}
                      onClick={this.copyInvoice2ToInvoicePro}
                    >
                      Copy to Invoice / Pro Number
                    </span>
                  )}
                </Col>
                <SCC.TruckNumber shipmentObjUpdateEvent={this.shipmentObjUpdateEvent}
                  value={this.state.shipment.loadDetails.truckNumber} userData={this.context}
                  hilightHide={`${this.state.truckNumberHilightHide}`}
                />
                <SCC.TrailerNumber shipmentObjUpdateEvent={this.shipmentObjUpdateEvent}
                  value={this.state.shipment.loadDetails.trailerNumber} userData={this.context}
                  hilightHide={`${this.state.trailerNumberHilightHide}`}
                />
                <SCC.DriverPhone shipmentObjUpdateEvent={this.shipmentObjUpdateEvent}
                  value={this.state.shipment.loadDetails.driverPhone} userData={this.context}
                  hilightHide={`${this.state.driverPhoneHilightHide}`}
                />
                <Col xs lg="2" className={`${this.state.invoiceDateHilightHide}`}>
                  <SCC.InvoiceDateFormField
                    render="show"
                    value={this.state.invoiceDate}
                    onChange={this.handleChangeGeneric}
                  />
                </Col>
              </Form.Row>
              : null}

            <Form.Row className="mt-3">

              {this.state.appointmentPickupDateHilightHide === 'show' ?
                (
                  <Form.Group
                    as={Col}
                    xs
                    lg="2"
                    className={`${this.state.appointmentPickupDateHilightHide}`}
                  >
                    <Form.Label>Appt. Pickup:</Form.Label>
                    <Form.Control
                      name="dates.appointmentPickupDate"
                      onChange={this.shipmentObjUpdateEvent}
                      type="date"
                      size="sm"
                      value={moment(
                        this.state.shipment.dates.appointmentPickupDate
                      ).format("YYYY-MM-DD")}
                    />
                  </Form.Group>
                ) : null
              }


              <SCC.PUApptDateLimited
                render={this.props.showFields.includes("appointmentPickupDateLimited")}
                apptPUDate={moment(
                  this.state.shipment.dates.appointmentPickupDate
                ).format("YYYY-MM-DD")}
                shipmentObjUpdateEvent={this.shipmentObjUpdateEvent}
                availableDate1={this.state.shipment.dates.availableDate1}
                inputFieldsReadOnly={this.state.inputFieldsReadOnly}
              />

              {this.state.appointmentPickupTimeInHilightHide === 'show' ?
                (
                  <Form.Group
                    as={Col}
                    xs
                    lg="2"
                    className={`${this.state.appointmentPickupTimeInHilightHide}`}
                  >
                    <Form.Label>Time In: </Form.Label>
                    <Form.Control
                      name="dates.appointmentPickupTimeIn"
                      onChange={this.shipmentObjUpdateEvent}
                      className="form-control"
                      type="time"
                      size="sm"
                      value={this.state.shipment.dates.appointmentPickupTimeIn}
                      disabled={this.state.inputFieldsReadOnly}
                    />
                  </Form.Group>
                ) : null}

              {this.state.appointmentPickupTimeOutHilightHide === 'show' ?
                (
                  <Form.Group
                    as={Col}
                    xs
                    lg="2"
                    className={`${this.state.appointmentPickupTimeOutHilightHide}`}
                  >
                    <Form.Label>Time Out:</Form.Label>
                    <Form.Control
                      name="dates.appointmentPickupTimeOut"
                      onChange={this.shipmentObjUpdateEvent}
                      className="form-control"
                      type="time"
                      size="sm"
                      value={this.state.shipment.dates.appointmentPickupTimeOut}
                      disabled={this.state.inputFieldsReadOnly}
                    />
                  </Form.Group>
                ) : null}

              <SCC.PickupETA
                render={this.props.showFields.includes("estpudate")}
                value={this.state.shipment.dates.estimatedPickup}
                onChange={this.shipmentObjUpdateEvent}
                shipment={this.state.shipment}
                inputFieldsReadOnly={this.state.inputFieldsReadOnly}
              />

              <SCC.PickupETA_Time
                render={this.props.showFields.includes("estputime")}
                value={this.state.shipment.dates.estimatedPickupTime}
                onChange={this.shipmentObjUpdateEvent}
                inputFieldsReadOnly={this.state.inputFieldsReadOnly}
              />
            </Form.Row>
            <Form.Row>


              {this.state.actualPickupDateHilightHide === 'show' ?

                (
                  <Form.Group
                    as={Col}
                    xs
                    lg="2"
                    className={`${this.state.appointmentDeliveryDateHilightHide}`}
                  >
                    <Form.Label>Appt. Delivery:</Form.Label>
                    <Form.Control
                      size="sm"
                      name="dates.appointmentDeliveryDate"
                      onChange={this.shipmentObjUpdateEvent}
                      className="form-control"
                      // type="text"
                      type="date"
                      value={moment(
                        this.state.shipment.dates.appointmentDeliveryDate
                      ).format("YYYY-MM-DD")}
                      disabled={this.state.inputFieldsReadOnly}
                    />
                  </Form.Group>
                ) : null}

              <SCC.DELApptDateLimited
                render={this.props.showFields.includes("appointmentDeliveryDateLimited")}
                apptDelDate={moment(
                  this.state.shipment.dates.appointmentDeliveryDate
                ).format("YYYY-MM-DD")}
                shipmentObjUpdateEvent={this.shipmentObjUpdateEvent}
                requestedDeliveryDate={this.state.shipment.dates.requestedDeliveryDate}
                inputFieldsReadOnly={this.state.inputFieldsReadOnly}
              />

              {this.state.appointmentDeliveryTimeInHilightHide === 'show' ?
                (
                  <Form.Group
                    as={Col}
                    xs
                    lg="2"
                    className={`${this.state.appointmentDeliveryTimeInHilightHide}`}
                  >
                    <Form.Label>Time In:</Form.Label>
                    <Form.Control
                      size="sm"
                      name="dates.appointmentDeliveryTimeIn"
                      onChange={this.shipmentObjUpdateEvent}
                      className="form-control"
                      type="time"
                      value={this.state.shipment.dates.appointmentDeliveryTimeIn}
                      disabled={this.state.inputFieldsReadOnly}
                    />
                  </Form.Group>
                ) : null}

              {this.state.appointmentDeliveryTimeOutHilightHide === 'show' ?
                (
                  <Form.Group
                    as={Col}
                    xs
                    lg="2"
                    className={`${this.state.appointmentDeliveryTimeOutHilightHide}`}
                  >
                    <Form.Label>Time Out:</Form.Label>
                    <Form.Control
                      size="sm"
                      name="dates.appointmentDeliveryTimeOut"
                      onChange={this.shipmentObjUpdateEvent}
                      className="form-control"
                      type="time"
                      value={this.state.shipment.dates.appointmentDeliveryTimeOut}
                      disabled={this.state.inputFieldsReadOnly}
                    />
                  </Form.Group>
                ) : null}

              <SCC.DeliveryETA
                render={this.props.showFields.includes("deliveryETA")}
                name="dates.estimatedDelivery"
                onChange={this.shipmentObjUpdateEvent}
                value={this.state.shipment.dates.estimatedDelivery}
                inputFieldsReadOnly={this.state.inputFieldsReadOnly}
              />

              <SCC.DeliveryETA_Time
                render={this.props.showFields.includes("estdeltime")}
                name="dates.estimatedDeliveryTime"
                onChange={this.shipmentObjUpdateEvent}
                value={this.state.shipment.dates.estimatedDeliveryTime}
                inputFieldsReadOnly={this.state.inputFieldsReadOnly}
              />


            </Form.Row>

            <Form.Row>
              <Form.Group
                as={Col}
                xs
                lg="2"
                className={`${this.state.actualPickupDateHilightHide}`}
              >
                <Form.Label>Actual Pickup:</Form.Label>
                <Form.Control
                  size="sm"
                  name="dates.pickupDate"
                  onChange={this.shipmentObjUpdateEvent}
                  className="form-control"
                  type="date"
                  value={moment(this.state.shipment.dates.pickupDate).format(
                    "YYYY-MM-DD"
                  )}
                  disabled={this.state.inputFieldsReadOnly}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                xs
                lg="2"
                className={`${this.showHilightOrHide("estimatedDeliveryDate")}`}
              >
                <Form.Label>Est. Del Date:</Form.Label>
                <Form.Control
                  size="sm"
                  name="dates.estimatedDeliveryDate"
                  onChange={this.shipmentObjUpdateEvent}
                  className="form-control"
                  type="date"
                  value={moment(
                    this.state.shipment.dates.estimatedDeliveryDate
                  ).format("YYYY-MM-DD")}
                  disabled={this.state.inputFieldsReadOnly}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                xs
                lg="2"
                className={`${this.state.actualPickupTimeInHilightHide}`}
              >
                <Form.Label>Time In:</Form.Label>
                <Form.Control
                  size="sm"
                  name="dates.pickupTimeIn"
                  onChange={this.shipmentObjUpdateEvent}
                  className="form-control"
                  type="time"
                  value={this.state.shipment.dates.pickupTimeIn}
                  disabled={this.state.inputFieldsReadOnly}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                xs
                lg="2"
                className={`${this.state.actualPickupTimeOutHilightHide}`}
              >
                <Form.Label>Time Out:</Form.Label>
                <Form.Control
                  size="sm"
                  name="dates.pickupTimeOut"
                  onChange={this.shipmentObjUpdateEvent}
                  className="form-control"
                  type="time"
                  value={moment(
                    this.state.shipment.dates.pickupTimeOut,
                    "HH:mm:ss"
                  ).format("HH:mm")}
                  disabled={this.state.inputFieldsReadOnly}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                xs
                lg="2"
                className={`${this.state.actualDeliveryDateHilightHide}`}
              >
                <Form.Label>Actual Delivery:</Form.Label>
                <Form.Control
                  size="sm"
                  name="dates.deliveryDate"
                  onChange={this.shipmentObjUpdateEvent}
                  className="form-control"
                  // type="text"
                  type="date"
                  value={
                    this.state.shipment.dates.deliveryDate === null
                      ? ""
                      : moment(this.state.shipment.dates.deliveryDate).format(
                        "YYYY-MM-DD"
                      )
                  }
                  disabled={this.state.inputFieldsReadOnly}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xs
                lg="2"
                className={`${this.state.actualDeliveryTimeInHilightHide}`}
              >
                <Form.Label>Time In:</Form.Label>
                <Form.Control
                  size="sm"
                  name="dates.deliveryTimeIn"
                  onChange={this.shipmentObjUpdateEvent}
                  className="form-control"
                  type="time"
                  value={moment(
                    this.state.shipment.dates.deliveryTimeIn,
                    "HH:mm:ss"
                  ).format("HH:mm")}
                  disabled={this.state.inputFieldsReadOnly}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xs
                lg="2"
                key={this.props.shipment.id}
                className={`${this.state.actualDeliveryTimeOutHilightHide}`}
              >
                <Form.Label>Time Out:</Form.Label>
                <Form.Control
                  size="sm"
                  name="dates.deliveryTimeOut"
                  onChange={this.shipmentObjUpdateEvent}
                  className="form-control"
                  type="time"
                  value={moment(
                    this.state.shipment.dates.deliveryTimeOut,
                    "HH:mm:ss"
                  ).format("HH:mm")}
                  disabled={this.state.inputFieldsReadOnly}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row className={`${this.state.documentsHilightHide}`}>
              <Col className="font-weight-bold mb-0">Provide Documents:</Col>
            </Form.Row>
            <Form.Row className={`${this.state.documentsHilightHide} mb-2`}>


              {this.props.shipment?.stops && this.props.showFields.includes("UploadDocsPerStop") ? (null) : (
                <Col xs lg="3">
                  <select
                    className="custom-select"
                    as="select"
                    size="sm"
                    onChange={this.documentUploadHandleChangeDocSelect}
                  >
                    {this.props.documents === undefined ? (
                      <>
                        <option value={null} defaultValue>
                          -- Select Document Type --
                        </option>
                        {this.context.clientCode === "foremost" ? <option value="AccRec">Accessorial Receipt</option> : null}
                        <option value="b3">B3</option>
                        <option value="BOL">BOL</option>
                        <option value="invoice">Invoice</option>
                        <option value="Signed-BOL"> Signed Bill of Lading</option>
                        <option value="Signed-BOLandInvoice">
                          Signed BOL & Invoice
                        </option>
                        {this.context.clientCode === "armetals" || this.context.clientCode === "fishinco" ? <option value="POD">POD</option> : null}
                        {this.context.clientCode === "armetals" ? <option value="Supplementary-Documents">Supplementary Documents</option> : null}
                        {this.context.clientCode === "alanwire" ?
                          <option value="Consignee-SignedBOL">
                            Consignee Signed BOL
                          </option> : null}
                      </>
                    ) : (
                      this.props.documents && this.props.documents.map((document, index) => (
                        <option key={index} value={document.value}>
                          {document.label}
                        </option>
                      ))
                    )}

                  </select>
                </Col>
              )}



              {this.props.shipment?.stops && this.props.showFields.includes("UploadDocsPerStop") ? (null)
                :
                (
                  <Col xs lg="3">
                    <label className="custom-file-label">
                      <input
                        size="sm"
                        type="file"
                        name="file"
                        // ref={this.fileInput}
                        onChange={this.documentUploadFileChangeHandler}
                      />
                      {this.state.documentUploadFileName
                        ? this.state.documentUploadFileName
                        : "Choose File"}
                    </label>

                  </Col>
                )

              }

              <Col xs lg="2">
                <button
                  className="btn btn-outline-info whiteBackgroundBtn"
                  onClick={this.modalOpenDocs}
                >
                  Documents &nbsp;
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16">
                    <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z" />
                  </svg>
                </button>
                <SCC.DocumentsModal
                  show={this.state.docsModalShow}
                  isUploadDocsPerStop={this.props.showFields.includes("UploadDocsPerStop")}
                  onHide={this.onHideDocs}
                  onHideDocs={this.onHideDocs}
                  id={this.props.shipment.id}
                  documents={this.props.shipment.documents}
                  updateWasSubmitted={this.state.updateWasSubmitted}
                  shipment={this.props.shipment}
                />
              </Col>

              <Col xs lg="12">
                {/* GenericModal is placed here for no reason, it has to be somewhere on this page but is not visible on the ShipmentCard */}
                <SCC.GenericModal
                  show={this.state.showGenericModal}
                  title={this.state.genericModalTitle}
                  body={this.state.genericModalBody}
                  genericModalHide={this.genericModalHide}
                />
                <SCC.SchuffGPSModal
                  show={this.state.showSchuffGPSModal}
                  title={this.state.schuffGPSModalTitle}
                  body={this.state.schuffGPSModalBody}
                  displayPhoneField={this.state.schuffGPSModalDisplayPhone}
                  displayTruckField={this.state.schuffGPSModalDisplayTruck}
                  phonePlaceholderText={this.state.schuffGPSModalPhonePlaceholderText}
                  onHide={this.hideSchuffGPSModal}
                  hideSchuffGPSModal={this.hideSchuffGPSModal}
                  id={this.props.shipment.id}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                xs
                lg="2"
                className={`${this.state.billedCostHilightHide}`}
              >
                <Form.Label>Billed Charges:</Form.Label>
                <InputGroup className="mb-3" size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                  </InputGroup.Prepend>

                  <Form.Control
                    size="sm"
                    name="charges.billedCost"
                    type="text"
                    className="form-control"
                    aria-label="Amount"
                    value={this.state.shipment.charges.billedCost}
                    onChange={this.shipmentObjUpdateEvent}
                    disabled={this.props.showFields.includes("billedCostDetails") || this.state.inputFieldsReadOnly}
                  />

                </InputGroup>
              </Form.Group>
              <Form.Group
                as={Col}
                xs
                lg="4"
                className={`${this.state.chargesMessageHilightHide}`}
              >
                <>
                  <Form.Label>Charges Notes:</Form.Label>
                  <InputGroup className="mb-3" size="sm">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">
                        {" "}
                        <i className="far fa-sticky-note" />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      size="sm"
                      name="chargesMessage"
                      type="text"
                      className="form-control"
                      aria-label="Amount"
                      value={this.state.chargesMessage}
                      onChange={this.handleChangeGeneric}
                      disabled={this.state.inputFieldsReadOnly}
                    />
                  </InputGroup>
                </>
              </Form.Group>
              <Form.Group
                as={Col}
                xs
                lg="4"
                className={`${this.state.signedByHilightHide}`}
              >
                <Form.Label> Signed For By:</Form.Label>
                <InputGroup className="mb-3" size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                      <i className="fas fa-file-signature" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>

                  <Form.Control
                    size="sm"
                    name="signedBy"
                    className="form-control"
                    type="text"
                    value={this.state.shipment.signedBy}
                    onChange={this.shipmentObjUpdateEvent}
                    disabled={this.state.inputFieldsReadOnly}
                  />
                </InputGroup>
              </Form.Group>
            </Form.Row>
          </Form>
          <Row>
            <Col>
              <SubmitUpdate
                clearAddedAccessorials={this.clearAddedAccessorials}
                newAccesorialRequests={this.state.newAccesorialRequests}
                propsShipment={this.props.shipment}
                shipment={this.state.shipment}
                setAndShowGenericModal={this.setAndShowGenericModal}
                chargesMessage={this.state.chargesMessage}
                buildFormDataForShipmentUpdate={
                  this.buildFormDataForShipmentUpdate
                }
                updatedFormData={this.state.updatedFormData}
                localUpDateSingleShipment={this.localUpDateSingleShipment}
                refreshShippments={this.props.refreshShippments}
                documentUploadFile={this.state.documentUploadFile}
                documentUploadFileType={this.state.documentUploadFileType}
                documentUploadBuildFormData={this.documentUploadBuildFormData}
                disabledForm={this.state.disabledForm}
                puTimeIn={this.state.shipment.dates.pickupTimeIn}
                puOutTime={this.state.shipment.dates.appointmentPickupTimeOut}
                delInTime={this.state.shipment.dates.deliveryTimeIn}
                delOutTime={this.state.shipment.dates.deliveryTimeOut}
                updateWasSubmittedIncrement={this.updateWasSubmittedIncrement}
              // checkAndSubmitChargesNotes={this.checkAndSubmitChargesNotes}
              />
            </Col>
            {this.props.showFields.includes("billedChargeChecklist") ?
              <Col>
                <SCC.BilledChargeChecklist shipment={this.state.shipment}
                  refreshShippments={this.props.refreshShippments}
                  documentUploadFile={this.state.documentUploadFile}
                  documentUploadFileType={this.state.documentUploadFileType}
                  documentUploadBuildFormData={this.documentUploadBuildFormData}
                  buildFormDataForShipmentUpdate={
                    this.buildFormDataForShipmentUpdate
                  }
                  accessoralRequestFeatureExists={this.showHilightOrHide("RequestAccessorials")}
                  updateWasSubmitted={this.state.updateWasSubmitted}
                />
              </Col>
              : null}
            <hr />
          </Row>
          {this.state.disabledForm === "disabled" ? <Row>
            <Col> <div class="alert alert-secondary d-inline-block" role="alert">This shipment is in OK status.  It cannot be updated.</div> </Col></Row> : null}


          {/* 
            ADVANCED BILLING SECTION (uses the billed cost modal from shippersEdge)
          */}

          {this.props.showFields.includes("billedCostDetails") ?
            <>
              <Row>
                <Col>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h5 class="">Billing</h5>
                </Col>
              </Row>
              <Row>
                <SCC.BilledChargeEditInDetail shipment={this.state.shipment}
                  refreshShippments={this.props.refreshShippments}
                  documentUploadFile={this.state.documentUploadFile}
                  documentUploadFileType={this.state.documentUploadFileType}
                  documentUploadBuildFormData={this.documentUploadBuildFormData}
                  buildFormDataForShipmentUpdate={
                    this.buildFormDataForShipmentUpdate
                  }
                  accessoralRequestFeatureExists={this.showHilightOrHide("RequestAccessorials")}
                  updateWasSubmitted={this.state.updateWasSubmitted}
                />
              </Row>
            </>
            : null}

          {this.props.showFields.includes("BilledChargeEditIndetail2") ?
            <>
              <Row>
                <Col>
                  <hr />
                </Col>
              </Row>
              <Row>
                <SCC.BilledChargeEditIndetail2 shipment={this.state.shipment}
                  refreshShippments={this.props.refreshShippments}
                  documentUploadFile={this.state.documentUploadFile}
                  documentUploadFileType={this.state.documentUploadFileType}
                  documentUploadBuildFormData={this.documentUploadBuildFormData}
                  buildFormDataForShipmentUpdate={
                    this.buildFormDataForShipmentUpdate
                  }
                  accessoralRequestFeatureExists={this.showHilightOrHide("RequestAccessorials")}
                  updateWasSubmitted={this.state.updateWasSubmitted}
                />
              </Row> </> : null}

        </div>
      );
    }
  }

  condRenderDetailBodyAndForm() {
    if (this.state.expandCardDetailsForm) {
      return (
        <Card.Body>
          {this.condRenderCardBodyDetail()}
          {/* EDITING FIELDS */}
          {/* Accept / Decline Tender buttons */}
          {this.condRenderAcceptDeclineTender()}
          {/* Accept / Decline Load Changes */}
          {this.condRenderAcceptDeclineLoadChanges()}
          {/* All Update Fields */}
          {this.condRenderAllUpdateFields()}
        </Card.Body>
      );
    } else {
      return (
        <span className="condRenderDetailBodyAndFormText">
          Click the shipment to see more details / update information.
        </span>
      );
    }
  }

  async populateShipmentV4() {
    // Currently the Carrier Portal uses the shipment service V4, however it calls for shipments with the translate=V2 url param and that changes the shape of the shipment object into a form that looks like the old shipment service V2.   We want to move to V4 but many components expet the shape of the shipment v2 object.   populateShipmentV4() will populate the shipmentV4 state with the V4 shipment object and then we can use the V4 object as needed.  Currenlty this is only called when a user opens the details of a given shipment.
    if (!this.state.shipmentV4) {
      let shipmentV4 = await getShipmentV4(this.props.shipment.id)
      if (shipmentV4) {
        this.setState({ shipmentV4: shipmentV4 })
      }
    }
  }

  // Triggers condRenderDetailBodyAndForm() to show the order details, and render other component such as all the form fields
  // Also populates shipmentV4 state
  showDetailsForms(event) {
    let element = event.target
    if (element.classList.contains('noExpand')) {
      return true;
    } else {
      if (this.state.messagesIcon === "unreadMessages") {
        // this.modalOpenMessaging(event);
      }
      this.setState((prevState) => ({
        expandCardDetailsForm: !prevState.expandCardDetailsForm,
      }));
    }
    this.populateShipmentV4()

  }

  render() {
    // Shipment Card Start
    return (
      // Shipment Card Headder
      <div className="ShipmentCard mt-3">
        <fieldset disabled={this.state.disabledForm}>
          <div
            key={`a2${this.state.checkBoxIDHash}${this.props.shipment.id}`}
            className="cardDiv"
          >
            <Card>
              <Card.Header onClick={this.showDetailsForms} className="pointer">
                <Row className="cardHeadderRow">
                  <Col
                    xs={1}
                    className={this.state.showHideCheckBoxForTableEditMode}
                  >
                    {" "}
                    <div className="custom-control form-control-lg custom-checkbox d-flex justify-content-center">
                      <input
                        className="form-check-input custom-control-input position-static form-control-lg"
                        type="checkbox"
                        id={`a${this.state.checkBoxIDHash}${this.props.shipment.id}`}
                        value={this.props.shipment.id}
                        checked={this.state.tableSelect}
                        name={this.props.shipment.id}
                        onChange={this.tableEditCheckEvent}
                      // onChange={this.props.handleChangeTableItem}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={`a${this.state.checkBoxIDHash}${this.props.shipment.id}`}
                      />
                    </div>
                  </Col>
                  <Col xs={3}>

                    <SCC.ReferenceNums
                      render="show"
                      shipment={this.props.shipment}
                    />
                    <SCC.SettlementID
                      render={this.state.settlementIDHilightHide}
                      shipment={this.props.shipment}
                    />
                    <SCC.SettlementDate
                      render={this.state.settlementDateHilightHide}
                      shipment={this.props.shipment}
                    />
                    <SCC.InvoiceNumber
                      render="show"
                      shipment={this.props.shipment}
                    />
                    <SCC.CarrierQuoteId
                      render={this.showHilightOrHide("carrierQuoteIdHilightHide")}
                      shipment={this.props.shipment}
                    />
                  </Col>
                  <Col xs={2}>
                    <SCC.ShipperConsSummary
                      render={"show"}
                      shipment={this.props.shipment}
                      mode={"ship"}

                    />
                    <br />
                    <br />
                    {/* Pickup Date Read Only Fields */}
                    <SCC.PickupDelDatesTimesStatic01
                      mode={"pickup"}
                      shipment={this.props.shipment}
                      customFields={this.props.shipment.customFields}
                    />
                  </Col>
                  {/* Account for multistop */}
                  <Col xs={this.state.arrowWidth} className="textAlignCenter">
                    <SCC.Arrow
                      shipment={this.props.shipment}
                    />
                  </Col>
                  <Col xs={3}>
                    <span className="shipCardMiddle3">
                      <SCC.ShipperConsSummary
                        render={"show"}
                        shipment={this.props.shipment}
                        mode={"cons"}

                      />
                      <br />
                      <br />
                      {/* Delivery Date Read Only Fields */}
                      <SCC.PickupDelDatesTimesStatic01
                        mode={"delivery"}
                        shipment={this.props.shipment}
                        customFields={this.props.shipment.customFields}
                      />{" "}
                      <SCC.GuaranteedDeliveryIcon
                        render={this.state.guaranteedDeliveryIconHilightHide}
                        shipment={this.props.shipment}

                      />
                      <SCC.KissnerCustomDelDate
                        shipment={this.props.shipment}
                      />
                    </span>
                  </Col>
                  <Col xs={2} className="textAlignRight">
                    <SCC.CurrentStatus
                      render="show"
                      shipment={this.props.shipment}
                    />
                    <SCC.AccessorialIcons
                      data={this.props.shipment.loadDetails.accessorials}
                      render={this.state.accessorialIconsHilightHide}
                      shipment={this.props.shipment}
                    />
                    <SCC.PaperClipIconAttachments
                      render="noAttachments"
                      onClick={this.modalOpenDocs}
                      numDocuments={this.props.shipment.documents.length}
                    />
                    <MailIcon
                      showHide={this.state.messagesHilightHide}
                      render={this.state.messagesIcon}
                      size="fa-2x"
                      onClick={this.modalOpenMessaging}
                      numMessages={this.state.numMessages}
                      numUnreadMessages={this.state.numUnreadMessages}
                    />
                  </Col>
                </Row>
              </Card.Header>
              <SCC.MessagesModal
                show={this.state.messagesModalShow}
                onHide={this.onHideMessages}
                idmessages={this.props.shipment.id}
                log={this.props.shipment.log}
                markMessagesReadLocally={this.markMessagesReadLocally}
              />

              {/* End Shipment Card Headder */}




              {this.condRenderDetailBodyAndForm()}
            </Card>
            <br />
          </div>
        </fieldset>
      </div>
    );
  }
}

ShipmentCard.defaultProps = {
  data: [],
};

export default ShipmentCard;
