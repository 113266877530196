import React, { useState } from 'react'

const UploadDocsPerStopDocs = ({ shipment, stop, checkForNewDocuments, documents }) => {
    console.log('shipment docs', shipment.docs)
    const [uploading, setUploading] = useState(false)
    const [uploadError, setUploadError] = useState(null)
    const [fileType, setFileType] = useState("")
    const [selectedFile, setSelectedFile] = useState(null)

    const stopDocuments = documents.filter(doc => doc.stop === stop.stopNumber)

    const circleStyle = {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        border: `6px solid ${stop.type === 'Pickup' ? '#0078d7' : '#d70000'}`,
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        fontSize: '18px',
        color: stop.type === 'Pickup' ? '#0078d7' : '#d70000',
        fontWeight: 'bold'
    }

    const stopColumnStyle = {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100px'
    }

    const handleUpload = async () => {
        if (!selectedFile || !fileType) {
            setUploadError("Please select a file and a document type.")
            return
        }

        setUploading(true)
        setUploadError(null)

        const formData = new FormData()
        formData.append('file', selectedFile)
        formData.append('shipmentId', shipment.id)
        formData.append('stop', stop.stopNumber)
        formData.append('fileType', fileType)

        try {
            const controller = new AbortController()
            const timeout = setTimeout(() => controller.abort(), 10000) // 10-second timeout

            const response = await fetch('https://www2.shippersedge.com/public/carrier/uploadFile.cfm?wsdl', {
                method: 'POST',
                body: formData,
                signal: controller.signal
            })

            clearTimeout(timeout)

            if (!response.ok) {
                throw new Error(`Upload failed: ${response.statusText}`)
            }

            // If successful, call checkForNewDocuments
            await checkForNewDocuments()
        } catch (error) {
            if (error.name === 'AbortError') {
                setUploadError('Upload timed out. Please try again.')
            } else {
                setUploadError('Upload failed. Please try again.')
            }
        } finally {
            setUploading(false)
            setFileType("") // Reset file type
            setSelectedFile(null) // Reset file selection
        }
    }

    return (
        <React.Fragment key={stop.id}>
            <div className="row align-items-start m-3" key={stop.id}>
                {/* Type Column */}
                <div className="col-2" style={stopColumnStyle}>
                    <div style={circleStyle}>
                        {stop.type === 'Pickup' ? 'P' : 'D'}
                    </div>
                </div>

                {/* Stop Details Column */}
                <div className="col-4">
                    <div key={stop.id} className="stop-item d-flex align-items-start">
                        <div>
                            <h5>{stop.name}</h5>
                            <p className="mb-1">{stop.address.line1}</p>
                            {stop.address.city && (
                                <p className="mb-1">
                                    {stop.address.city}, {stop.address.state} {stop.address.zip}
                                </p>
                            )}
                            {stop.address.country && (
                                <p className="mb-1">{stop.address.country}</p>
                            )}
                        </div>
                    </div>
                </div>

                {/* Documents Column */}
                <div className="col-6">
                    {stopDocuments.length > 0 ? (
                        <ul className="list-unstyled mt-2">
                            {stopDocuments.map(doc => {
                                let formattedDate = 'Unknown Date'
                                if (doc.createdAt) {
                                    try {
                                        const isoDate = doc.createdAt.split('T')
                                        const datePart = isoDate[0]
                                        let timePart = isoDate[1]?.split('.')[0]
                                        timePart = timePart.slice(0, -3)
                                        const [year, month, day] = datePart.split('-')
                                        const months = [
                                            'January', 'February', 'March', 'April', 'May', 'June',
                                            'July', 'August', 'September', 'October', 'November', 'December'
                                        ]
                                        const humanReadableDate = `${months[parseInt(month, 10) - 1]} ${parseInt(day, 10)}, ${year}`
                                        formattedDate = `${humanReadableDate} ${timePart}`
                                    } catch {
                                        formattedDate = 'Invalid Date'
                                    }
                                }

                                return (
                                    <li key={doc.id}>
                                        <a href={doc.url} target="_blank" rel="noopener noreferrer">
                                            {doc.name} ({doc.type}) </a> - {formattedDate}

                                    </li>
                                )
                            })}
                        </ul>
                    ) : (
                        <p className="text-muted mt-2">No documents</p>
                    )}

                    {/* Upload Section */}
                    <div className="mt-3">
                        <label htmlFor={`file-upload-${stop.id}`} style={{ cursor: 'pointer', fontSize: '24px', color: '#0078d7' }}>
                            <i className="fas fa-plus-circle"></i>
                        </label>
                        <input
                            id={`file-upload-${stop.id}`}
                            type="file"
                            onChange={(e) => setSelectedFile(e.target.files[0])}
                            style={{ display: 'none' }}
                        />

                        {selectedFile && (
                            <>
                                <p className="mt-2">Selected File: {selectedFile.name}</p>
                                <div className="form-group">
                                    <label htmlFor={`file-type-${stop.id}`} className="form-label">Document Type</label>
                                    <select
                                        id={`file-type-${stop.id}`}
                                        value={fileType}
                                        onChange={(e) => setFileType(e.target.value)}
                                        className="form-control"
                                    >
                                        <option value="" disabled>Select a document type</option>
                                        <option value="b3">B3</option>
                                        <option value="BOL">BOL</option>
                                        <option value="invoice">Invoice</option>
                                        <option value="Signed-BOL">Signed Bill of Lading</option>
                                        <option value="Signed-BOLandInvoice">Signed BOL Invoice</option>
                                    </select>
                                </div>
                                <button
                                    className="btn btn-primary mt-2"
                                    onClick={handleUpload}
                                    disabled={uploading}
                                >
                                    {uploading ? 'Uploading...' : 'Upload'}
                                </button>
                                {uploadError && <p className="text-danger mt-2">{uploadError}</p>}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default UploadDocsPerStopDocs