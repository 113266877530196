import React, { useState, useEffect } from 'react'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as moment from "moment";
import UploadDocsPerStopDocs from './UploadDocsPerStopDocs';

const UploadDocsPerStop = ({ show, onHide, shipment }) => {

    const [modalState, setModalState] = useState('loaded')
    const [documents, setDocuments] = useState([])
    const [uploading, setUploading] = useState(false)
    const [uploadError, setUploadError] = useState(null)
    const [fileType, setFileType] = useState("")
    const [selectedFile, setSelectedFile] = useState(null)


    useEffect(() => {
        checkForNewDocuments()
    }, [])


    const checkForNewDocuments = () => {
        setModalState('loading')
        let webquotecontrol = shipment.id
        fetch("https://api.shippersedge.com/v4/shipment/" + webquotecontrol + "?include=all,gpsStatus,location,messages,logTypeNotice,documents&translate=v2", {
            "method": "GET",
            "headers": {
                "x-api-key": "vdhy8wwjUJrwhxFpfuECTiAW"
            }
        }).then(response => response.json()).then(data => {
            const resortedDocuments = data.documents.sort(
                (doc1, doc2) => doc1.id < doc2.id
            );
            setDocuments(resortedDocuments)
            console.log('resortedDocuments', resortedDocuments)
            setModalState('loaded')
        })
            .catch(err => {
                console.error(err);
                setModalState('error')
            }
            );
    }

    const handleUpload = async () => {
        if (!selectedFile || !fileType) {
            setUploadError("Please select a file and a document type.")
            return
        }

        setUploading(true)
        setUploadError(null)

        const formData = new FormData()
        formData.append('file', selectedFile)
        formData.append('shipmentId', shipment.id)
        formData.append('fileType', fileType) // No 'stop' field here

        try {
            const controller = new AbortController()
            const timeout = setTimeout(() => controller.abort(), 10000) // 10-second timeout

            const response = await fetch(
                'https://www2.shippersedge.com/public/carrier/uploadFile.cfm?wsdl',
                {
                    method: 'POST',
                    body: formData,
                    signal: controller.signal,
                }
            )

            clearTimeout(timeout)

            if (!response.ok) {
                throw new Error(`Upload failed: ${response.statusText}`)
            }

            // Refresh documents after a successful upload
            await checkForNewDocuments()
        } catch (error) {
            if (error.name === 'AbortError') {
                setUploadError('Upload timed out. Please try again.')
            } else {
                setUploadError('Upload failed. Please try again.')
            }
        } finally {
            setUploading(false)
            setFileType("") // Reset file type
            setSelectedFile(null) // Reset file selection
        }
    }


    const now = Date.now();
    const today = moment(now).format("MM-DD-YYYY");



    return (
        <div>
            <Modal show={show} onHide={onHide} size="lg" >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Documents Per Stop
                    </Modal.Title>

                    {modalState === 'loading' ? (
                        <div class="spinner-grow" role="status" onClick={() => checkForNewDocuments()}>
                            <span class="sr-only">Loading...</span>
                        </div>
                    ) : (
                        <svg
                            onClick={() => checkForNewDocuments()}
                            title="Reload / Check For New Documents"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                        </svg>
                    )}
                </Modal.Header>


                <Modal.Body>
                    {/* If moal state is loading */}
                    {modalState === 'loading' ? (
                        <>
                            <div className="d-flex justify-content-center w-100  ">
                                <div className="d-flex flex-direction-row justify-content-center alert alert-primary border-primary w-50">
                                    <div>Loading documents...</div>
                                    <div class="spinner-border ml-2" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null}

                    {/* If modal state is loaded */}

                    {modalState === 'loaded' ? (
                        <>
                            <Row>
                                <div className="d-flex justify-content-center w-100  ">
                                    <div className="alert alert-warning border border-warning" role="alert">
                                        Not seeing the documents you just uploaded? <br />
                                        Click the reload icon to check for new documents.
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <Col>
                                    <strong>Current Documents:</strong>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <a
                                        href={
                                            "https://www2.shippersedge.com/includes/cfm/static_other.cfm?d=rateagreement&i=" +
                                            shipment.id +
                                            "&print&wsdl"
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Rate Agreement
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <a
                                        href={
                                            "https://www2.shippersedge.com/includes/cfm/static_other.cfm?d=pickuprequest&i=" +
                                            shipment.id +
                                            "&print&wsdl"
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Pickup Request
                                    </a>
                                </Col>
                            </Row>
                            <hr />
                            {/* documents without a .stop value */}

                            <Row>
                                <Col>
                                    <strong>Uploaded Documents:</strong> <br />
                                    {documents.map((item, index) => {
                                        if (item.name === "null") {
                                            return null
                                        } else {
                                            if (item?.stop === null) {
                                                return (
                                                    <span id={item.id} key={index}>
                                                        <a
                                                            href={item.url}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {item.type} -{" "}
                                                        </a>{" "}
                                                        Uploaded at:{" "}
                                                        {moment(item.createdAt).format("MM-DD-YYYY, HH:MM:SS")}{" "}
                                                        {today === moment(item.createdAt).format("MM-DD-YYYY") ? (
                                                            <span style={{ backgroundColor: "#f9e79f" }}>
                                                                (Uploaded Today)
                                                            </span>
                                                        ) : null}
                                                        <br />
                                                    </span>
                                                );
                                            } else {
                                                return null
                                            }
                                        }
                                    })}
                                    <div className="mt-3">
                                        <label
                                            htmlFor="file-upload"
                                            style={{ cursor: 'pointer', fontSize: '24px', color: '#0078d7' }}
                                        >
                                            <i className="fas fa-plus-circle"></i>
                                        </label>
                                        <input
                                            id="file-upload"
                                            type="file"
                                            onChange={(e) => setSelectedFile(e.target.files[0])}
                                            style={{ display: 'none' }}
                                        />

                                        {selectedFile && (
                                            <>
                                                <p className="mt-2">Selected File: {selectedFile.name}</p>
                                                <div className="form-group">
                                                    <label htmlFor="file-type" className="form-label">
                                                        Document Type
                                                    </label>
                                                    <select
                                                        id="file-type"
                                                        value={fileType}
                                                        onChange={(e) => setFileType(e.target.value)}
                                                        className="form-control"
                                                        style={{ width: "30%" }}
                                                    >
                                                        <option value="" disabled>
                                                            Select a document type
                                                        </option>
                                                        <option value="b3">B3</option>
                                                        <option value="BOL">BOL</option>
                                                        <option value="invoice">Invoice</option>
                                                        <option value="Signed-BOL">Signed Bill of Lading</option>
                                                        <option value="Signed-BOLandInvoice">
                                                            Signed BOL Invoice
                                                        </option>
                                                    </select>
                                                </div>
                                                <button
                                                    className="btn btn-primary mt-2"
                                                    onClick={handleUpload}
                                                    disabled={uploading}
                                                >
                                                    {uploading ? 'Uploading...' : 'Upload'}
                                                </button>
                                                {uploadError && (
                                                    <p className="text-danger mt-2">{uploadError}</p>
                                                )}
                                            </>
                                        )}
                                    </div>

                                </Col>
                            </Row>

                            {shipment?.stops ? (
                                <>
                                    <hr />
                                    <Row>
                                        <Col>
                                            <p><strong>Per Stop Uploaded Documents:</strong></p>
                                        </Col>
                                    </Row>
                                </>

                            ) : null}

                            {shipment.stops.map((stop, index) => {
                                return (
                                    <UploadDocsPerStopDocs shipment={shipment} documents={documents} checkForNewDocuments={checkForNewDocuments} stop={stop} />
                                )
                            })}

                        </>
                    ) : null}


                    {/* if modal state is error */}

                    {modalState === 'error' ? (

                        <>
                            <div>There was an error loading documents, please try clicking the reload icon.</div>

                        </>
                    ) : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


        </div>
    )
}

export default UploadDocsPerStop